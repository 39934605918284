import React, { useState, useEffect, useRef, useCallback } from "react";
import { StyleSheet, View, ScrollView, Text, TextInput, TouchableOpacity, SafeAreaView, ActivityIndicator, Modal, Image, Linking } from "react-native";
import { useNavigation } from "@react-navigation/native";
import RNPickerSelect from "react-native-picker-select";
import Checkbox from "expo-checkbox";
import InsideHead from "../components/insideHeader";
import MoblieHead from "../components/mHeader";
import IntlPhoneInput from "../components/IntlPhoneInput";
import Footer from "../components/footer";
import axios from "axios";
import { API_MIDDLEWARE, API_REGION } from "@env";
import { WebView } from "react-native-webview";
import AsyncStorage from "../components/AsyncStorage";
import CalendarPicker from "react-native-calendar-picker";
import moment from "moment";
import Captcha from "../components/captcha";
import i18n from "../components/i18n";
import queryString from "query-string";
import CookieConsent from "../components/CookieConsent";
import NewMobileHeader from "../components/newMobileHeader";

const SignUp = () => {
  const navigation = useNavigation();

  const [optEmail, setOptEmail] = useState(false);
  const [optSMS, setOptSMS] = useState(false);
  const [step, setStep] = useState(1);
  const [title, setTitle] = useState("");
  const [titleErr, setTitleErr] = useState(false);
  const [lastname, setLastname] = useState("");
  const [lastnameErr, setLastnameErr] = useState(false);
  const [firstname, setFirstname] = useState("");
  const [firstnameErr, setFirstnameErr] = useState(false);
  const [birthday, setBirthday] = useState("");
  const [birthdayErr, setBirthdayErr] = useState(false);
  const [birthdayMaxErr, setBirthdayMaxErr] = useState(false);
  const [gender, setGender] = useState("");
  const [genderErr, setGenderErr] = useState(false);
  const [language, setLanguage] = useState("");
  const [languageErr, setLanguageErr] = useState(false);
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState(false);
  const [emailUniqueErr, setEmailUniqueErr] = useState(false);
  const [confirmEmail, setConfirmEmail] = useState("");
  const [confirmEmailErr, setConfirmEmailErr] = useState(false);
  const [phone, setPhone] = useState("");
  const [phoneErr, setPhoneErr] = useState(false);
  const [phoneUniqueErr, setPhoneUniqueErr] = useState(false);
  const [validPhone, setValidPhone] = useState(false);
  const [dialCode, setDialCode] = useState("");
  const [password, setPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordErr, setConfirmPasswordErr] = useState(false);
  const [passwordFormatErr, setPasswordFormatErr] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentWebview, setPaymentWebview] = useState(false);
  const [paymentURL, setPaymentURL] = useState("");
  const [paymentDetail, setPaymentDetail] = useState({
    invoiceNo: "",
    desc: "",
    amount: "",
  });
  const [calendarView, setCalendarView] = useState(false);
  const [memberCode, setMemberCode] = useState("");
  const [recaptchaValid, setRecaptchaValid] = useState(false);
  const [recaptchaErr, setRecaptchaErr] = useState(false);
  const [whereHear, setWhereHear] = useState("");
  const [whereHearErr, setWhereHearErr] = useState(false);
  const [birthOfDate, setBirthOfDate] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [promoCodeErr, setPromoCodeErr] = useState(false);
  const [promoCodeValidMsg, setPromoCodeValidMsg] = useState(false);
  const [signUpAmt, setSignUpAmt] = useState(0);
  const [referralCode, setReferralCode] = useState("");

  const [agreeTnc, setAgreeTnc] = useState(false);
  const [agreeTncErr, setAgreeTncErr] = useState(false);

  const [checkingPromoCode, setCheckingPromoCode] = useState(false);
  const [screenSize, setScreenSize] = useState("")

  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    const parsed = queryString.parse(location.search, { decode: false });
    setReferralCode(parsed.ref);

    if (parsed.ref) {
      let data = {
        code: parsed.ref,
      };
      checkReferral(data, (ref) => {
        if (ref.status == "error") {
          alert(capitalize(ref.message));
        }
      });
    }

    return () => {
      mounted.current = false;
    };
  }, []);

  const goStep = (step) => {
    let errors = [];
    setIsLoading(false);
    setTitleErr(false);
    setLastnameErr(false);
    setFirstnameErr(false);
    setEmailErr(false);
    setEmailUniqueErr(false);
    setConfirmEmailErr(false);
    setPhoneErr(false);
    setPhoneUniqueErr(false);
    setPasswordErr(false);
    setConfirmPasswordErr(false);
    setBirthdayErr(false);
    setGenderErr(false);
    setPasswordFormatErr(false);
    setBirthdayMaxErr(false);
    setLanguageErr(false);
    setPromoCodeErr(false);
    setPromoCodeValidMsg(false);

    if (step == 2) {
      setIsLoading(true);

      if (title == "") {
        errors.push("err");
        setTitleErr(true);
      }

      if (firstname == "") {
        errors.push("err");
        setFirstnameErr(true);
      }

      if (lastname == "") {
        errors.push("err");
        setLastnameErr(true);
      }

      if (!validPhone) {
        errors.push("err");
        setPhoneErr(true);
      }

      if (!validEmail(email)) {
        errors.push("err");
        setEmailErr(true);
      } else if (email != confirmEmail) {
        errors.push("err");
        setConfirmEmailErr(true);
      }

      if (birthday == "") {
        errors.push("err");
        setBirthdayErr(true);
      } else if (new Date(birthOfDate) >= new Date(moment().subtract(18, "years").format("YYYY-MM-DD"))) {
        errors.push("err");
        setBirthdayMaxErr(true);
      }

      if (gender == "") {
        errors.push("err");
        setGenderErr(true);
      }

      if (language == "") {
        errors.push("err");
        setLanguageErr(true);
      }

      if (!errors.length) {
        let data = {
          email: email,
          mobile: dialCode + phone.replace(/\s/g, ""),
        };

        checkUnique(data, (unique) => {
          console.log(unique);
          if (unique.status == "success") {
            setStep(step);
            setIsLoading(false);
          } else {
            if (unique.message == "Both mobile and email already used") {
              setEmailUniqueErr(true);
              setPhoneUniqueErr(true);
            }

            if (unique.message == "Email already used") {
              setEmailUniqueErr(true);
            }

            if (unique.message == "Mobile already used") {
              setPhoneUniqueErr(true);
            }

            setIsLoading(false);
          }
        });
      } else {
        setIsLoading(false);
      }
    } else if (step == 3) {
      if (password == "") {
        errors.push("err");
        setPasswordErr(true);
      } else if (password != confirmPassword) {
        errors.push("err");
        setConfirmPasswordErr(true);
      } else if (!password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^-])[A-Za-z\d@$!%*?&#^-]{8,}$/)) {
        errors.push("err");
        setPasswordFormatErr(true);
      }

      if (!errors.length) {
        setStep(step);
      }
    } else if (step == 4) {
      if (agreeTnc) setStep(step);
      else setAgreeTncErr(true);
    } else {
      setStep(step);
      setIsLoading(false);
    }
  };

  const onChangeText = ({ dialCode, unmaskedPhoneNumber, phoneNumber, isVerified }) => {
    console.log(dialCode, unmaskedPhoneNumber, phoneNumber, isVerified);
    setPhone(unmaskedPhoneNumber.replaceAll("-", ""));
    setDialCode(dialCode.replace("+", ""));
    setValidPhone(isVerified);
  };

  const submitForm = () => {
    if(checkingPromoCode) return;

    // if promo code is empty or invalid
    if (!promoCode || promoCodeErr) {
      setPromoCodeErr(true);
      return;
    }

    setRecaptchaErr(false);
    setIsLoading(true);
    setPromoCodeErr(false);
    setWhereHearErr(false)

    if (whereHear == "") {
      setWhereHearErr(true)
      setIsLoading(false)
    } else if (!recaptchaValid) {
      setRecaptchaErr(true);
      setIsLoading(false);
    } else {
      if (memberCode == "") {
        let username = dialCode + phone.replace(/\s/g, "");

        let data = {
          username: username,
          password: password,
          title: title,
          firstname: firstname,
          lastname: lastname,
          phone: phone.replace(/\s/g, ""),
          dialCode: dialCode,
          email: email,
          birthday: birthOfDate,
          gender: gender,
          whereHear: whereHear,
          language: language,
          promocode: promoCode,
        };

        if (referralCode != "") {
          data.referral_code = referralCode;
        }

        createCustomer(data, (c1) => {
          setIsLoading(true);
          if (c1.status == "success") {
            paymentCountdown()
            console.log("create customer success");
            data.code = c1.code;
            setMemberCode(c1.code);

            // console.log("c1.code :", c1.code)
            // console.log("memberCode :", memberCode)

            if (optSMS) {
              let dataOptinSMS = {
                code: data.code,
                channel: "Mobile",
              };

              optIn(dataOptinSMS, (c2) => {
                if (c2.status == "success") {
                  console.log("optin SMS success");
                  return true;
                } else {
                  isErr = true;
                  throw "optin SMS error";
                }
              });
            }

            if (optEmail) {
              let dataOptinEmail = {
                code: data.code,
                channel: "Email",
              };

              optIn(dataOptinEmail, (c3) => {
                if (c3.status == "success") {
                  console.log("optin Email success");
                  return true;
                } else {
                  isErr = true;
                  throw "optin Email error";
                }
              });
            }

            // handle signup amt = $0
            if (signUpAmt == "0" || signUpAmt == 0 || signUpAmt == "") {
              setIsLoading(false);
              FinishSignUp(c1.code);
            } else {
              initPayment(c1.code);
              //setIsLoading(false);
            }
          } else {
            if (JSON.stringify(c1.hint.errors)) {
              alert(capitalize(c1.message) + "\n" + JSON.stringify(c1.hint.errors));
            } else {
              alert(capitalize(c1.message));
            }

            setIsLoading(false);
          }
        });
      } else {
        if (signUpAmt == "0" || signUpAmt == 0 || signUpAmt == "") {
          setIsLoading(false);
          FinishSignUp(memberCode);
        } else {
          initPayment(memberCode);
          //setIsLoading(false);
        }
      }
    }
  };

  const initPayment = (code) => {

    setIsLoading(true);

    let data = {
      code: code
    }
    getPaymentURL(data, (pay) => {
      if (pay.status == "success") {
        setPaymentURL(pay.url);

        let payD = paymentDetail;
        payD.invoiceNo = pay.invoiceno;
        payD.desc = pay.description;
        payD.amount = pay.amount;

        console.log("Payment detail :", paymentDetail);

        setPaymentDetail(payD);
        setPaymentWebview(true);
setIsLoading(false);

        // const openPaymentWebview = () => {
        //   setPaymentWebview(true);
        //   clearTimeout(paymentTimeout);
        // };

        // let paymentTimeout = setTimeout(openPaymentWebview, 5000);

        // setPaymentWebview(true);

        // if (paymentTimeout) {
        //   setPaymentWebview(false);
        // }
      } else {
        console.log("Init payment failed");
setIsLoading(false);
      }
    });
  };

  const CheckPayment = () => {
    setIsLoading(true);

    let data = {
      invoiceNo: paymentDetail.invoiceNo,
    };

    console.log("checkPayment-data :", data);

    checkPaymentResult(data, (result) => {
      if (result.respCode == "0000") {
        FinishSignUp(memberCode);
      } else {
        setIsLoading(false);
        alert("Payment failed - " + result.respDesc);
        // setTimeout(() => {
        //   alert("Transaction has failed. Please retry payment or attempt registration again after 10 minutes.");
// }, 4000);
        // navigation.push("Login");
      }
    });
  };

  const FinishSignUp = (code) => {
    setIsLoading(true);

    console.log("FinishSignUp-code:", code);

    let data2 = {
      code: code,
    };

    confirmCustomer(data2, (customer) => {
      if (customer.status == "success") {
        let store = {
          code: code,
          username: email,
          region: API_REGION,
        };

        console.log("confirmCustomer-store :", store);

        AsyncStorage.storeData("mCode", JSON.stringify(store));
        setIsLoading(false);
        navigation.push("Profile");
      } else {
        alert(customer.message);
        setIsLoading(false);
      }
    });
  };

  const createCustomer = (data, callback) => {
    let formData = new FormData();
    formData.append("method", "createCust");
    formData.append("password", data.password);
    formData.append("title", data.title);
    formData.append("first_name", data.firstname);
    formData.append("last_name", data.lastname);
    formData.append("mobile", data.phone);
    formData.append("areacode", data.dialCode);
    formData.append("email", data.email);
    formData.append("birthday", data.birthday);
    formData.append("gender", data.gender);
    formData.append("field1__c", data.whereHear);
    formData.append("language", data.language);
    formData.append("region", API_REGION);
    formData.append("promocode", promoCode);

    if (data.referral_code) {
      formData.append("referral_code", data.referral_code);
    }

    // console.log("create customer data :", data)

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        callback(false);
      });
  };

  const confirmCustomer = (data, callback) => {
    let formData = new FormData();
    formData.append("method", "confirmCust");
    formData.append("code", data.code);
    formData.append("region", API_REGION);
    formData.append("promocode", promoCode);

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        callback(false);
      });
  };

  const optIn = (data, callback) => {
    let formData = new FormData();
    formData.set("method", "custOptin");
    formData.set("code", data.code);
    formData.set("channel", data.channel);
    formData.set("optin_reason", "Web Registration Form Optin");
    formData.set("region", API_REGION);

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        callback(false);
      });
  };

  const getPaymentURL = (data, callback) => {
    let formData = new FormData();
    formData.set("method", "payment");
    formData.set("promocode", promoCode);
    formData.set("region", API_REGION);
formData.set("code", data.code);

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        callback(false);
      });
  };

  const checkPaymentResult = (data, callback) => {
    let formData = new FormData();
    formData.set("method", "paymentChk");
    formData.set("region", API_REGION);
    formData.set("invoiceno", data.invoiceNo);

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        callback(false);
      });
  };

  const checkUnique = (data, callback) => {
    let formData = new FormData();
    formData.set("method", "checkDup");
    formData.set("email", data.email);
    formData.set("mobile", data.mobile);
    formData.set("region", API_REGION);

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        callback(false);
      });
  };

  const validEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const onDateChange = (date) => {
    let birth = moment(new Date(date.toString())).format("DD-MM-YYYY");
    setBirthOfDate(moment(new Date(date.toString())).format("YYYY-MM-DD"));
    setBirthday(birth);
    setCalendarView(false);
  };

  const onVerify = (token) => {
    console.log("success!", token);
  };

  const onExpire = () => {
    console.warn("expired!");
  };

  const checkCaptcha = (status) => {
    setRecaptchaValid(status);
  };

  // Promo code debounce handling:
  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const checkPromoCode = (data) => {
    setCheckingPromoCode(true);
    setIsLoading(true)
    // setSignUpAmt(88);
    setPromoCodeErr(false);
    setPromoCodeValidMsg(false);

    // console.log("promo code length first check :", data.length)

    let formData = new FormData();
    formData.append("method", "chkPromoCode");
    formData.append("promocode", data);
    formData.append("region", API_REGION);

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        console.log("promoCode response :", response.data);
        // console.log("response status :", response.data.status);
        // console.log("promo code length :", promoCode.length);
        // if (data.length > 0) {
        if (response.data.status == "success") {
          // setSignUpAmt(response.data.benefits[0].amount);
          setPromoCodeValidMsg(true);
          // console.log("Correct code handling.")
        } else if (response.data.status == "failed") {
          // setSignUpAmt(88);
          setPromoCodeErr(true);
          // console.log("Warn code handling.")
        }
        // } else {
        //   // setSignUpAmt(88);
        //   setPromoCodeErr(false);
        //   setPromoCodeValidMsg(false);
        //   // console.log("No input promo code.")
        // }
        setCheckingPromoCode(false);
        setIsLoading(false)
      })
      .catch(function (error) {
        c.log("checkPromoCode error:", error);
      });
  };

  const debouncedCheckPromoCode = useCallback(debounce(checkPromoCode), []);

  const checkReferral = (data, callback) => {
    let formData = new FormData();
    formData.set("method", "chkReferral");
    formData.set("code", data.code);

    axios
      .post(API_MIDDLEWARE, formData)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        callback(false);
      });
  };

  const capitalize = (string) => {
    if (string) {
      return string.replace(/^./, (str) => str.toUpperCase());
    } else {
      return "";
    }
  };

  const TextLink = ({ text, link, splitBy, ...rest }) => {
    let parts = text.split(splitBy);
    return (
      <Text {...rest}>
        <Text>{parts[0]}</Text>
        <Text className="underline cursor-pointer" onPress={() => Linking.openURL(link)}>
          {parts[1]}
        </Text>
        <Text>{parts[2]}</Text>
      </Text>
    );
  };

  const paymentCountdown = () => {
    setTimeout(() => {
      alert("Payment timeout!")
      location.reload()
    }, (1000 * 60 * 20))
  }

  return (
    <SafeAreaView className="flex-1 justify-start items-center w-full bg-white md:bg-[#F5F5F5]" pointerEvents={!isLoading ? "auto" : "none"}>
      {isLoading && (
        <View className="absolute left-0 right-0 top-0 bottom-0 justify-center items-center bg-[#f3f3f394] z-10 w-full h-full" pointerEvents={"none"}>
          <ActivityIndicator size="large" color={"#BD9B60"} />
        </View>
      )}
      <InsideHead />
      <NewMobileHeader />
      <MoblieHead title={i18n.t("signUp.signUp")} backTo={"Login"} />
      <View className="hidden w-4/5 max-w-7xl h-auto m-auto bg-white p-5 mt-6 md:flex">
        <Text className="text-black text-2xl mt-6 mb-4 uppercase font-Crimson">{i18n.t("signUp.title")}</Text>
        {step === 1 && (
          <>
            <View className="flex flex-row justify-start items-center content-center pt-6">
              <Text className="w-7 h-7 bg-brandgold rounded-full leading-7 text-white mr-4 text-center">1</Text>
              <Text className="text-sm text-[#494949] mr-4">{i18n.t("signUp.step1")} </Text>
              <Text className="w-7 h-7 bg-[#BBBBBB] rounded-full leading-7 text-white mr-4 text-center">2</Text>
              <Text className="text-sm text-[#494949] mr-4">{i18n.t("signUp.step2")}</Text>
              <Text className="w-7 h-7 bg-[#BBBBBB] rounded-full leading-7 text-white mr-4 text-center">3</Text>
              <Text className="text-sm text-[#494949] mr-4">{i18n.t("signUp.step3")}</Text>
              <Text className="w-7 h-7 bg-[#BBBBBB] rounded-full leading-7 text-white mr-4 text-center">4</Text>
              <Text className="text-sm text-[#494949] mr-4">{i18n.t("signUp.step4")}</Text>
            </View>

            <View className="flex flex-row flex-wrap items-start justify-between pb-4">
              <View className="w-[48%]">
                <Text className="text-[#4E5969] text-xs mt-4 mb-1 font-PingFangSC">{i18n.t("signUp.salutation")}</Text>

                <RNPickerSelect
                  value={title}
                  onValueChange={(value) => setTitle(value)}
                  items={[
                    { label: i18n.t("signUp.salutation_opt2"), value: "Mr" },
                    { label: i18n.t("signUp.salutation_opt3"), value: "Mrs" },
                    { label: i18n.t("signUp.salutation_opt4"), value: "Mdm" },
                    { label: i18n.t("signUp.salutation_opt5"), value: "Miss" },
                    { label: i18n.t("signUp.salutation_opt6"), value: "Dr" },
                  ]}
                  placeholder={{
                    label: i18n.t("signUp.salutation_opt1"),
                    value: "",
                  }}
                  style={pickerSelectStyles}
                />
                {/* <TextInput placeholder="" className="h-9 w-full bg-[#F2F3F5] color-[#121212] px-4" ></TextInput> */}

                {titleErr && <Text className="color-red-500 my-1">{i18n.t("signUp.salutation_errMsg")}</Text>}

                <Text className="text-[#4E5969] text-xs mt-4 mb-1 font-PingFangSC">{i18n.t("signUp.firstname")}</Text>
                <TextInput placeholder="" className="h-9 w-full bg-[#F2F3F5] color-[#121212] px-4" value={firstname} onChangeText={setFirstname}></TextInput>

                {firstnameErr && <Text className="color-red-500 my-1">{i18n.t("signUp.firstname_errMsg")}</Text>}

                <Text className="text-[#4E5969] text-xs mt-4 mb-1 font-PingFangSC">{i18n.t("signUp.lastname")}</Text>
                <TextInput placeholder="" className="h-9 w-full bg-[#F2F3F5] color-[#121212] px-4" value={lastname} onChangeText={setLastname}></TextInput>

                {lastnameErr && <Text className="color-red-500 my-1">{i18n.t("signUp.lastname_errMsg")}</Text>}

                <Text className="text-[#4E5969] text-xs mt-4 mb-1 font-PingFangSC">{i18n.t("signUp.birth")}</Text>
                <View className="relative">
                  <TextInput placeholder="DD-MM-YYYY" className="h-9 w-full bg-[#F2F3F5] color-[#121212] px-4" value={birthday} onChangeText={setBirthday} editable={false}></TextInput>
                  <TouchableOpacity className="absolute top-3 right-3" onPress={() => setCalendarView(true)}>
                    <Image source={require("../assets/icons/icon-calendar.png")} className="w-[12px] h-[12px]" />
                  </TouchableOpacity>
                </View>

                {birthdayErr && <Text className="color-red-500 my-1">{i18n.t("signUp.birth_errMsg")}</Text>}
                {birthdayMaxErr && <Text className="color-red-500 my-1">{i18n.t("signUp.birth_errMsg2")}</Text>}

                <Text className="text-[#4E5969] text-xs mt-4 mb-1 font-PingFangSC">{i18n.t("signUp.language_preference")}</Text>
                <RNPickerSelect
                  value={language}
                  onValueChange={(value) => setLanguage(value)}
                  items={[
                    {
                      label: i18n.t("signUp.language_preference_opt2"),
                      value: "en-us",
                    },
                    {
                      label: i18n.t("signUp.language_preference_opt3"),
                      value: "zh-cn",
                    },
                    {
                      label: i18n.t("signUp.language_preference_opt4"),
                      value: "ko-kr",
                    },
                  ]}
                  placeholder={{
                    label: i18n.t("signUp.language_preference_opt1"),
                    value: "",
                  }}
                  style={pickerSelectStyles}
                />

                {languageErr && <Text className="color-red-500 my-1">{i18n.t("signUp.language_preference_errMsg")}</Text>}

                <View className="flex flex-row flex-wrap justify-start items-start w-full my-auto">
                  <TouchableOpacity
                    className="w-auto h-8 max-h-8 bg-[#F2F3F5] mt-9 mr-3 px-4"
                    onPress={() => {
                      navigation.push("Login");
                    }}
                  >
                    <Text className="text-[#4E5969] w-full h-8 leading-8 text-center font-semibold font-PingFangSC">{i18n.t("signUp.cancel")}</Text>
                  </TouchableOpacity>

                  <TouchableOpacity className="w-auto h-8 max-h-8 bg-brandgold mt-9 mr-3 px-4" onPress={() => {goStep(2); setScreenSize("desktop")}}>
                    <Text className="text-white w-full h-8 leading-8 text-center font-semibold font-PingFangSC">{i18n.t("signUp.next")}</Text>
                  </TouchableOpacity>
                </View>
              </View>

              <View className="w-[48%]">
                <Text className="text-[#4E5969] text-xs mt-4 mb-1 font-PingFangSC">{i18n.t("signUp.mobile")}</Text>
                {/* <TextInput placeholder="" className="h-9 w-full bg-[#F2F3F5] color-[#121212] px-4" ></TextInput> */}
                <IntlPhoneInput
                  onChangeText={onChangeText}
                  defaultCountry={API_REGION}
                  containerStyle={{
                    flex: 1,
                    flexDirection: "row",
                    paddingHorizontal: 0,
                  }}
                  flagStyle={{ display: "none" }}
                  phoneInputStyle={{
                    color: "#86909C",
                    height: 34,
                    width: "100%",
                    borderWidth: 1,
                    borderColor: "#F2F3F5",
                    backgroundColor: "#F2F3F5",
                    paddingHorizontal: 15,
                  }}
                  dialCodeTextStyle={{
                    color: "#86909C",
                    height: 34,
                    lineHeight: 34,
                    width: "100%",
                    borderWidth: 1,
                    borderColor: "#F2F3F5",
                    backgroundColor: "#F2F3F5",
                    paddingHorizontal: 15,
                  }}
                  placeholder={phone}
                />

                {phoneErr && <Text className="color-red-500 my-1">{i18n.t("signUp.mobile_errMsg")}</Text>}
                {phoneUniqueErr && <Text className="color-red-500 my-1">{i18n.t("signUp.mobile_errMsg2")} </Text>}

                <Text className="text-[#4E5969] text-xs mt-4 mb-1 font-PingFangSC">{i18n.t("signUp.emailAddress")}</Text>
                <TextInput placeholder="" className="h-9 w-full bg-[#F2F3F5] color-[#121212] px-4" value={email} onChangeText={setEmail}></TextInput>

                {emailErr && <Text className="color-red-500 my-1">{i18n.t("signUp.emailAddress_errMsg")}</Text>}
                {emailUniqueErr && <Text className="color-red-500 my-1">{i18n.t("signUp.emailAddress_errMsg2")} </Text>}

                <Text className="text-[#4E5969] text-xs mt-4 mb-1 font-PingFangSC">{i18n.t("signUp.confirmEmailAddress")}</Text>
                <TextInput placeholder="" className="h-9 w-full bg-[#F2F3F5] color-[#121212] px-4" value={confirmEmail} onChangeText={setConfirmEmail}></TextInput>

                {confirmEmailErr && <Text className="color-red-500 my-1">{i18n.t("signUp.confirmEmailAddress_errMsg")}</Text>}

                <Text className="text-[#4E5969] text-xs mt-4 mb-1 font-PingFangSC">{i18n.t("signUp.gender")}</Text>
                <RNPickerSelect
                  value={gender}
                  onValueChange={(value) => setGender(value)}
                  items={[
                    { label: i18n.t("signUp.gender_opt2"), value: "male" },
                    { label: i18n.t("signUp.gender_opt3"), value: "female" },
                  ]}
                  placeholder={{
                    label: i18n.t("signUp.gender_opt1"),
                    value: "",
                  }}
                  style={pickerSelectStyles}
                  useNativeAndroidPickerStyle={false}
                />

                {genderErr && <Text className="color-red-500 my-1">{i18n.t("signUp.gender_errMsg")}</Text>}
              </View>
            </View>
          </>
        )}

        {step === 2 && (
          <>
            <View className="flex flex-row justify-start items-center pt-6">
              <TouchableOpacity onPress={() => goStep(1)}>
                <Text className="w-7 h-7 bg-brandgold rounded-full leading-7 text-white mr-4 text-center">✓</Text>
              </TouchableOpacity>
              <Text className="text-sm text-[#494949] mr-4">{i18n.t("signUp.step1")} </Text>
              <Text className="w-7 h-7 bg-brandgold rounded-full leading-7 text-white mr-4 text-center">2</Text>
              <Text className="text-sm text-[#494949] mr-4">{i18n.t("signUp.step2")}</Text>
              <Text className="w-7 h-7 bg-[#BBBBBB] rounded-full leading-7 text-white mr-4 text-center">3</Text>
              <Text className="text-sm text-[#494949] mr-4">{i18n.t("signUp.step3")}</Text>
              <Text className="w-7 h-7 bg-[#BBBBBB] rounded-full leading-7 text-white mr-4 text-center">4</Text>
              <Text className="text-sm text-[#494949] mr-4">{i18n.t("signUp.step4")}</Text>
            </View>

            <View className="w-[70%] my-[100px] mx-auto">
              <Text className="text-xs mt-6">{i18n.t("signUp.password_format")}</Text>
              <Text className="text-[#4E5969] text-xs mt-4 mb-1 font-PingFangSC">{i18n.t("signUp.password")}</Text>
              <TextInput placeholder="" className="h-9 w-full bg-[#F2F3F5] color-[#121212] px-4" secureTextEntry={true} value={password} onChangeText={setPassword}></TextInput>

              {passwordErr && <Text className="color-red-500 my-1">{i18n.t("signUp.password_errMsg")}</Text>}
              {passwordFormatErr && <Text className="color-red-500 my-1">{i18n.t("signUp.password_format")}</Text>}

              <Text className="text-[#4E5969] text-xs mt-4 mb-1 font-PingFangSC">{i18n.t("signUp.confirm_password")}</Text>
              <TextInput placeholder="" className="h-9 w-full bg-[#F2F3F5] color-[#121212] px-4" secureTextEntry={true} value={confirmPassword} onChangeText={setConfirmPassword}></TextInput>

              {confirmPasswordErr && <Text className="color-red-500 my-1">{i18n.t("signUp.confirm_password_errMsg")}</Text>}

              <View className="flex flex-row flex-wrap justify-start items-start w-full my-auto">
                <TouchableOpacity className="w-auto h-8 max-h-8 bg-gray-400 mt-9 mr-3 px-4" onPress={() => goStep(1)}>
                  <Text className="text-white w-full h-8 leading-8 text-center font-semibold font-PingFangSC">{i18n.t("signUp.back")}</Text>
                </TouchableOpacity>

                {/*<TouchableOpacity className="w-auto h-8 max-h-8 bg-[#F2F3F5] mt-9 mr-3 px-4" onPress={() => { navigation.push('Login') }}>
                                <Text className="text-[#4E5969] w-full h-8 leading-8 text-center font-semibold font-PingFangSC">{i18n.t('signUp.cancel')}</Text>
                            </TouchableOpacity> */}

                <TouchableOpacity className="w-auto h-8 max-h-8 bg-brandgold mt-9 mr-3 px-4" onPress={() => goStep(3)}>
                  <Text className="text-white w-full h-8 leading-8 text-center font-semibold font-PingFangSC">{i18n.t("signUp.next")}</Text>
                </TouchableOpacity>
              </View>
            </View>
          </>
        )}

        {step === 3 && (
          <>
            <View className="flex flex-row justify-start items-center pt-6">
              <TouchableOpacity onPress={() => goStep(1)}>
                <Text className="w-7 h-7 bg-brandgold rounded-full leading-7 text-white mr-4 text-center">✓</Text>
              </TouchableOpacity>
              <Text className="text-sm text-[#494949] mr-4">{i18n.t("signUp.step1")} </Text>
              <TouchableOpacity onPress={() => goStep(2)}>
                <Text className="w-7 h-7 bg-brandgold rounded-full leading-7 text-white mr-4 text-center">✓</Text>
              </TouchableOpacity>
              <Text className="text-sm text-[#494949] mr-4">{i18n.t("signUp.step2")}</Text>
              <Text className="w-7 h-7 bg-brandgold rounded-full leading-7 text-white mr-4 text-center">3</Text>
              <Text className="text-sm text-[#494949] mr-4">{i18n.t("signUp.step3")}</Text>
              <Text className="w-7 h-7 bg-[#BBBBBB] rounded-full leading-7 text-white mr-4 text-center">4</Text>
              <Text className="text-sm text-[#494949] mr-4">{i18n.t("signUp.step4")}</Text>
            </View>

            <View className="w-[70%] my-[100px] mx-auto">
              <Text className="text-sm mt-6 flex justify-start items-start">
                <Checkbox className="mr-3" value={agreeTnc} onValueChange={setAgreeTnc} color={agreeTnc ? "#000000" : undefined} />
                <TextLink text={i18n.t("signUp.step3_consent_opt")} link={"https://members.imperialtreasure.co.kr/tnc"} splitBy={"<link>"} />
              </Text>
              <Text className="text-sm mt-2 flex justify-start items-start">
                <TextLink className="ml-7" text={i18n.t("signUp.step3_consent_txt")} link={"https://members.imperialtreasure.co.kr/tnc"} splitBy={"<link>"} />
              </Text>

              {agreeTncErr && <Text className="color-red-500 my-1 ml-7">{i18n.t("signUp.tnc_errMsg")}</Text>}

              <Text className="text-xs mt-6 ml-7">{i18n.t("signUp.step3_title")}</Text>
              <Text className="text-sm mt-2 mb-3 ml-7">{i18n.t("signUp.step3_q")}</Text>

              <View className="flex-row items-center mb-4 ml-7">
                <Checkbox className="mr-3" value={optEmail} onValueChange={setOptEmail} color={optEmail ? "#000000" : undefined} />
                <Text onPress={() => setOptEmail(!optEmail)}>{i18n.t("signUp.step3_q_opt1")}</Text>
              </View>

              <View className="flex-row items-center mb-4 ml-7">
                <Checkbox className="mr-3" value={optSMS} onValueChange={setOptSMS} color={optSMS ? "#000000" : undefined} />
                <Text onPress={() => setOptSMS(!optSMS)}>{i18n.t("signUp.step3_q_opt2")}</Text>
              </View>

              <View className="flex flex-row flex-wrap justify-start items-start w-full my-auto">
                <TouchableOpacity className="w-auto h-8 max-h-8 bg-gray-400 mt-9 mr-3 px-4" onPress={() => goStep(2)}>
                  <Text className="text-white w-full h-8 leading-8 text-center font-semibold font-PingFangSC">{i18n.t("signUp.back")}</Text>
                </TouchableOpacity>

                {/* <TouchableOpacity className="w-auto h-8 max-h-8 bg-[#F2F3F5] mt-9 mr-3 px-4" onPress={() => { navigation.push('Login') }}>
                    <Text className="text-[#4E5969] w-full h-8 leading-8 text-center font-semibold font-PingFangSC">{i18n.t('signUp.cancel')}</Text>
                </TouchableOpacity> */}

                <TouchableOpacity className="w-auto h-8 max-h-8 bg-brandgold mt-9 mr-3 px-4" onPress={() => goStep(4)}>
                  <Text className="text-white w-full h-8 leading-8 text-center font-semibold font-PingFangSC">{i18n.t("signUp.next")}</Text>
                </TouchableOpacity>
              </View>
            </View>
          </>
        )}

        {step === 4 && (
          <>
            <View className="flex flex-row justify-start items-center pt-6">
              <TouchableOpacity onPress={() => goStep(1)}>
                <Text className="w-7 h-7 bg-brandgold rounded-full leading-7 text-white mr-4 text-center">✓</Text>
              </TouchableOpacity>
              <Text className="text-sm text-[#494949] mr-4">{i18n.t("signUp.step1")} </Text>
              <TouchableOpacity onPress={() => goStep(2)}>
                <Text className="w-7 h-7 bg-brandgold rounded-full leading-7 text-white mr-4 text-center">✓</Text>
              </TouchableOpacity>
              <Text className="text-sm text-[#494949] mr-4">{i18n.t("signUp.step2")}</Text>
              <TouchableOpacity onPress={() => goStep(3)}>
                <Text className="w-7 h-7 bg-brandgold rounded-full leading-7 text-white mr-4 text-center">✓</Text>
              </TouchableOpacity>
              <Text className="text-sm text-[#494949] mr-4">{i18n.t("signUp.step3")}</Text>
              <Text className="w-7 h-7 bg-brandgold rounded-full leading-7 text-white mr-4 text-center">4</Text>
              <Text className="text-sm text-[#494949] mr-4">{i18n.t("signUp.step4")}</Text>
            </View>

            <View className="w-[70%] my-[100px] mx-auto">
              <Text className="text-[#4E5969] text-xs mt-4 mb-1 font-PingFangSC">*{i18n.t("signUp.step4_q")}</Text>
              <RNPickerSelect
                value={whereHear}
                onValueChange={(value) => setWhereHear(value)}
                items={[
                  {
                    label: i18n.t("signUp.step4_q_opt2"),
                    value: "Paradise City, Fine Chinese Cuisine",
                  },
                  {
                    label: i18n.t("signUp.step4_q_opt3"),
                    value: "Paradise City Plaza, Dim Sum & Steam Boat",
                  },
                  {
                    label: i18n.t("signUp.step4_q_opt16"),
                    value: "Word of Mouth",
                  },
                  {
                    label: i18n.t("signUp.step4_q_opt17"),
                    value: "Social Media",
                  },
                  {
                    label: i18n.t("signUp.step4_q_opt18"),
                    value: "Others",
                  },
                ]}
                placeholder={{
                  label: i18n.t("signUp.step4_q_opt1"),
                  value: "",
                }}
                style={pickerSelectStyles}
                useNativeAndroidPickerStyle={false}
              />

              {whereHearErr && <Text className="color-red-500 my-1">{i18n.t("signUp.step4_q_err")}</Text>}

              <View className="w-full h-[0.5px] bg-[#E5E6E8] mt-6 mb-1"></View>

              <Text className="text-[#4E5969] text-xs mt-4 mb-1 font-PingFangSC">{i18n.t("signUp.promo_code")}</Text>
              <TextInput
                placeholder={i18n.t("signUp.promo_code_input_msg")}
                className="h-9 w-full bg-[#F2F3F5] color-[#121212] px-4"
                value={promoCode}
                onChange={(e) => {
                  setPromoCode(e.target.value);

                  debouncedCheckPromoCode(e.target.value);
                }}
              ></TextInput>

              {promoCodeErr && <Text className="color-red-500 my-1">{i18n.t("signUp.promo_code_err_msg")}</Text>}
              {promoCodeValidMsg && <Text className="text-green-500 my-1">{i18n.t("signUp.promo_code_re_msg")}</Text>}

              <View className="w-full h-[0.5px] bg-[#E5E6E8] mt-6 mb-1"></View>

              <Text className="text-xl font-bold text-right mt-4 mb-6">
                {i18n.t("signUp.total")} KRW{signUpAmt}
              </Text>

              { screenSize == "desktop" && (
                <Captcha returnStatus={(status) => {setRecaptchaValid(status) }} />  
              )}
              
              {recaptchaErr && <Text className="color-red-500 my-1">{i18n.t("signUp.captcha_errMsg")}</Text>}

              <View className="flex flex-row flex-wrap justify-start items-start w-full my-auto">
                <TouchableOpacity className="w-auto h-8 max-h-8 bg-gray-400 mt-9 mr-3 px-4" onPress={() => goStep(3)}>
                  <Text className="text-white w-full h-8 leading-8 text-center font-semibold font-PingFangSC">{i18n.t("signUp.back")}</Text>
                </TouchableOpacity>

                {/* <TouchableOpacity className="w-auto h-8 max-h-8 bg-[#F2F3F5] mt-9 mr-3 px-4" onPress={() => { navigation.push('Login') }}>
                                <Text className="text-[#4E5969] w-full h-8 leading-8 text-center font-semibold font-PingFangSC">{i18n.t('signUp.cancel')}</Text>
                            </TouchableOpacity> */}

                <TouchableOpacity className="w-auto h-8 max-h-8 bg-brandgold mt-9 mr-3 px-4" onPress={() => submitForm()}>
                  <Text className="text-white w-full h-8 leading-8 text-center font-semibold font-PingFangSC">{i18n.t("signUp.submitForm")}</Text>
                </TouchableOpacity>
              </View>
            </View>
          </>
        )}
      </View>
      <ScrollView className="w-full mx-1 max-w-2xl px-4 pb-24 md:hidden">
        <Text className="text-black text-2xl mt-6 mb-4 uppercase font-Crimson">{i18n.t("signUp.title")}</Text>
        {/* <Text className="text-[#979797] text-xs mt-1">
          {i18n.t("signUp.mustFill")}
        </Text> */}
        {step === 1 && (
          <>
            <View className="flex flex-row justify-start items-center pt-6">
              <Text className="w-7 h-7 bg-brandgold rounded-full leading-7 text-white mr-4 text-center">1</Text>
              <Text className="text-sm text-[#494949] mr-4">{i18n.t("signUp.step1")}</Text>
              <Text className="w-7 h-7 bg-[#BBBBBB] rounded-full leading-7 text-white mr-4 text-center">2</Text>
              <Text className="w-7 h-7 bg-[#BBBBBB] rounded-full leading-7 text-white mr-4 text-center">3</Text>
              <Text className="w-7 h-7 bg-[#BBBBBB] rounded-full leading-7 text-white mr-4 text-center">4</Text>
            </View>

            <Text className="text-[#494949] text-base mt-7">{i18n.t("signUp.step1")}</Text>
            <View className="w-full h-[0.5px] bg-brandgold mt-2"></View>

            <Text className="text-[#4E5969] text-xs mt-4 mb-1 font-PingFangSC">{i18n.t("signUp.salutation")}</Text>

            <RNPickerSelect
              value={title}
              onValueChange={(value) => setTitle(value)}
              items={[
                { label: i18n.t("signUp.salutation_opt2"), value: "Mr" },
                { label: i18n.t("signUp.salutation_opt3"), value: "Mrs" },
                { label: i18n.t("signUp.salutation_opt4"), value: "Mdm" },
                { label: i18n.t("signUp.salutation_opt5"), value: "Miss" },
                { label: i18n.t("signUp.salutation_opt6"), value: "Dr" },
              ]}
              placeholder={{
                label: i18n.t("signUp.salutation_opt1"),
                value: "",
              }}
              style={pickerSelectStyles}
              useNativeAndroidPickerStyle={false}
            />

            {titleErr && <Text className="color-red-500 my-1">{i18n.t("signUp.salutation_errMsg")}</Text>}
            {/* <TextInput placeholder="" className="h-9 w-full bg-[#F2F3F5] color-[#121212] px-4" ></TextInput> */}

            <Text className="text-[#4E5969] text-xs mt-4 mb-1 font-PingFangSC">{i18n.t("signUp.firstname")}</Text>
            <TextInput placeholder="" className="h-9 w-full bg-[#F2F3F5] color-[#121212] px-4" value={firstname} onChangeText={setFirstname}></TextInput>

            {firstnameErr && <Text className="color-red-500 my-1">{i18n.t("signUp.firstname_errMsg")}</Text>}

            <Text className="text-[#4E5969] text-xs mt-4 mb-1 font-PingFangSC">{i18n.t("signUp.lastname")}</Text>
            <TextInput placeholder="" className="h-9 w-full bg-[#F2F3F5] color-[#121212] px-4" value={lastname} onChangeText={setLastname}></TextInput>

            {lastnameErr && <Text className="color-red-500 my-1">{i18n.t("signUp.lastname_errMsg")}</Text>}

            <Text className="text-[#4E5969] text-xs mt-4 mb-1 font-PingFangSC">{i18n.t("signUp.mobile")}</Text>
            <IntlPhoneInput
              onChangeText={onChangeText}
              defaultCountry={API_REGION}
              containerStyle={{
                flex: 1,
                flexDirection: "row",
                paddingHorizontal: 0,
              }}
              flagStyle={{ display: "none" }}
              dialCodeTextStyle={{
                color: "#86909C",
                lineHeight: 32,
                borderColor: "#F2F3F5",
                backgroundColor: "#F2F3F5",
                paddingHorizontal: 10,
              }}
              phoneInputStyle={{
                color: "#86909C",
                height: 32,
                width: "100%",
                borderWidth: 1,
                borderColor: "#F2F3F5",
                backgroundColor: "#F2F3F5",
                paddingHorizontal: 15,
              }}
              placeholder={phone}
            />

            {phoneErr && <Text className="color-red-500 my-1">{i18n.t("signUp.mobile_errMsg")}</Text>}
            {phoneUniqueErr && <Text className="color-red-500 my-1">{i18n.t("signUp.mobile_errMsg2")} </Text>}

            <Text className="text-[#4E5969] text-xs mt-4 mb-1 font-PingFangSC">{i18n.t("signUp.emailAddress")}</Text>
            <TextInput placeholder="" className="h-9 w-full bg-[#F2F3F5] color-[#121212] px-4" value={email} onChangeText={setEmail}></TextInput>

            {emailErr && <Text className="color-red-500 my-1">{i18n.t("signUp.emailAddress_errMsg")}</Text>}
            {emailUniqueErr && <Text className="color-red-500 my-1">{i18n.t("signUp.emailAddress_errMsg2")} </Text>}

            <Text className="text-[#4E5969] text-xs mt-4 mb-1 font-PingFangSC">{i18n.t("signUp.confirmEmailAddress")}</Text>
            <TextInput placeholder="" className="h-9 w-full bg-[#F2F3F5] color-[#121212] px-4" value={confirmEmail} onChangeText={setConfirmEmail}></TextInput>

            {confirmEmailErr && <Text className="color-red-500 my-1">{i18n.t("signUp.confirmEmailAddress_errMsg")}</Text>}

            <Text className="text-[#4E5969] text-xs mt-4 mb-1 font-PingFangSC">{i18n.t("signUp.birth")}</Text>
            <View className="relative">
              <TextInput placeholder="DD-MM-YYYY" className="h-9 w-full bg-[#F2F3F5] color-[#121212] px-4" value={birthday} onChangeText={setBirthday} editable={false}></TextInput>
              <TouchableOpacity className="absolute top-3 right-3" onPress={() => setCalendarView(true)}>
                <Image source={require("../assets/icons/icon-calendar.png")} className="w-[12px] h-[12px]" />
              </TouchableOpacity>
            </View>

            {birthdayErr && <Text className="color-red-500 my-1">{i18n.t("signUp.birth_errMsg")}</Text>}
            {birthdayMaxErr && <Text className="color-red-500 my-1">{i18n.t("signUp.birth_errMsg2")}</Text>}

            <Text className="text-[#4E5969] text-xs mt-4 mb-1 font-PingFangSC">{i18n.t("signUp.gender")}</Text>
            <RNPickerSelect
              value={gender}
              onValueChange={(value) => setGender(value)}
              items={[
                { label: i18n.t("signUp.gender_opt2"), value: "male" },
                { label: i18n.t("signUp.gender_opt3"), value: "female" },
              ]}
              placeholder={{ label: i18n.t("signUp.gender_opt1"), value: "" }}
              style={pickerSelectStyles}
              useNativeAndroidPickerStyle={false}
            />

            {genderErr && <Text className="color-red-500 my-1">{i18n.t("signUp.gender_errMsg")}</Text>}

            <Text className="text-[#4E5969] text-xs mt-4 mb-1 font-PingFangSC">{i18n.t("signUp.language_preference")}</Text>
            <RNPickerSelect
              value={language}
              onValueChange={(value) => setLanguage(value)}
              items={[
                {
                  label: i18n.t("signUp.language_preference_opt2"),
                  value: "en-us",
                },
                {
                  label: i18n.t("signUp.language_preference_opt3"),
                  value: "zh-cn",
                },
                {
                  label: i18n.t("signUp.language_preference_opt4"),
                  value: "ko-kr",
                },
              ]}
              placeholder={{
                label: i18n.t("signUp.language_preference_opt1"),
                value: "",
              }}
              style={pickerSelectStyles}
              useNativeAndroidPickerStyle={false}
            />

            {languageErr && <Text className="color-red-500 my-1">{i18n.t("signUp.language_preference_errMsg")}</Text>}
          </>
        )}

        {step === 2 && (
          <>
            <View className="flex flex-row justify-start items-center pt-6">
              <TouchableOpacity onPress={() => goStep(1)}>
                <Text className="w-7 h-7 bg-brandgold rounded-full leading-7 text-white mr-4 text-center">✓</Text>
              </TouchableOpacity>
              <Text className="w-7 h-7 bg-brandgold rounded-full leading-7 text-white mr-4 text-center">2</Text>
              <Text className="text-sm text-[#494949] mr-4">{i18n.t("signUp.step2")}</Text>
              <Text className="w-7 h-7 bg-[#BBBBBB] rounded-full leading-7 text-white mr-4 text-center">3</Text>
              <Text className="w-7 h-7 bg-[#BBBBBB] rounded-full leading-7 text-white mr-4 text-center">4</Text>
            </View>

            <Text className="text-[#494949] text-base mt-7">{i18n.t("signUp.step2")}</Text>
            <View className="w-full h-[0.5px] bg-brandgold mt-2"></View>

            <Text className="text-xs mt-6">{i18n.t("signUp.password_format")}</Text>
            <Text className="text-[#4E5969] text-xs mt-4 mb-1 font-PingFangSC">{i18n.t("signUp.password")}</Text>
            <TextInput placeholder="" className="h-9 w-full bg-[#F2F3F5] color-[#121212] px-4" secureTextEntry={true} value={password} onChangeText={setPassword}></TextInput>
            {/* <Text className="text-[#979797] text-xs mt-1">密码至少为8个字符, 其中需包含大小字母和数字</Text> */}

            {passwordErr && <Text className="color-red-500 my-1">{i18n.t("signUp.password_errMsg")}</Text>}
            {passwordFormatErr && <Text className="color-red-500 my-1">{i18n.t("signUp.password_format")}</Text>}

            <Text className="text-[#4E5969] text-xs mt-4 mb-1 font-PingFangSC">{i18n.t("signUp.confirm_password")}</Text>
            <TextInput placeholder="" className="h-9 w-full bg-[#F2F3F5] color-[#121212] px-4" secureTextEntry={true} value={confirmPassword} onChangeText={setConfirmPassword}></TextInput>

            {confirmPasswordErr && <Text className="color-red-500 my-1">{i18n.t("signUp.confirm_password_errMsg")}</Text>}
          </>
        )}

        {step === 3 && (
          <>
            <View className="flex flex-row justify-start items-center pt-6">
              <TouchableOpacity onPress={() => goStep(1)}>
                <Text className="w-7 h-7 bg-brandgold rounded-full leading-7 text-white mr-4 text-center">✓</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => goStep(2)}>
                <Text className="w-7 h-7 bg-brandgold rounded-full leading-7 text-white mr-4 text-center">✓</Text>
              </TouchableOpacity>
              <Text className="w-7 h-7 bg-brandgold rounded-full leading-7 text-white mr-4 text-center">3</Text>
              <Text className="text-sm text-[#494949] mr-4">{i18n.t("signUp.step3")}</Text>
              <Text className="w-7 h-7 bg-[#BBBBBB] rounded-full leading-7 text-white mr-4 text-center">4</Text>
            </View>

            <Text className="text-[#494949] text-base mt-7">{i18n.t("signUp.step3")}</Text>
            <View className="w-full h-[0.5px] bg-brandgold mt-2"></View>

            <Text className="text-sm mt-6 flex justify-start items-start">
              <Checkbox className="mr-3" value={agreeTnc} onValueChange={setAgreeTnc} color={agreeTnc ? "#000000" : undefined} />
              <TextLink text={i18n.t("signUp.step3_consent_opt")} link={"https://members.imperialtreasure.co.kr/tnc"} splitBy={"<link>"} />
            </Text>
            <Text className="text-sm mt-1 flex justify-start items-start">
              <TextLink className="ml-7" text={i18n.t("signUp.step3_consent_txt")} link={"https://members.imperialtreasure.co.kr/tnc"} splitBy={"<link>"} />
            </Text>

            {agreeTncErr && <Text className="color-red-500 my-1 ml-7">{i18n.t("signUp.tnc_errMsg")}</Text>}

            <Text className="text-xs mt-6 ml-7">{i18n.t("signUp.step3_title")}</Text>
            <Text className="text-sm mt-2 mb-3 ml-7">{i18n.t("signUp.step3_q")}</Text>

            <View className="flex-row items-center mb-4 ml-7">
              <Checkbox className="mr-3" value={optEmail} onValueChange={setOptEmail} color={optEmail ? "#000000" : undefined} />
              <Text onPress={() => setOptEmail(!optEmail)}>{i18n.t("signUp.step3_q_opt1")}</Text>
            </View>

            <View className="flex-row items-center mb-4 ml-7">
              <Checkbox className="mr-3" value={optSMS} onValueChange={setOptSMS} color={optSMS ? "#000000" : undefined} />
              <Text onPress={() => setOptSMS(!optSMS)}>{i18n.t("signUp.step3_q_opt2")}</Text>
            </View>
          </>
        )}

        {step === 4 && (
          <>
            <View className="flex flex-row justify-start items-center pt-6">
              <TouchableOpacity onPress={() => goStep(1)}>
                <Text className="w-7 h-7 bg-brandgold rounded-full leading-7 text-white mr-4 text-center">✓</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => goStep(2)}>
                <Text className="w-7 h-7 bg-brandgold rounded-full leading-7 text-white mr-4 text-center">✓</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => goStep(3)}>
                <Text className="w-7 h-7 bg-brandgold rounded-full leading-7 text-white mr-4 text-center">✓</Text>
              </TouchableOpacity>
              <Text className="w-7 h-7 bg-brandgold rounded-full leading-7 text-white mr-4 text-center">4</Text>
              <Text className="text-sm text-[#494949] mr-4">{i18n.t("signUp.step4")}</Text>
            </View>

            <Text className="text-[#494949] text-base mt-7">{i18n.t("signUp.step4")}</Text>
            <View className="w-full h-[0.5px] bg-brandgold mt-2"></View>

            <Text className="text-[#4E5969] text-xs mt-4 mb-1 font-PingFangSC">*{i18n.t("signUp.step4_q")}</Text>
            <RNPickerSelect
              value={whereHear}
              onValueChange={(value) => setWhereHear(value)}
              items={[
                {
                  label: i18n.t("signUp.step4_q_opt2"),
                  value: "Paradise City, Fine Chinese Cuisine",
                },
                {
                  label: i18n.t("signUp.step4_q_opt3"),
                  value: "Paradise City Plaza, Dim Sum & Steam Boat",
                },
                {
                  label: i18n.t("signUp.step4_q_opt16"),
                  value: "Word of Mouth",
                },
                {
                  label: i18n.t("signUp.step4_q_opt17"),
                  value: "Social Media",
                },
                {
                  label: i18n.t("signUp.step4_q_opt18"),
                  value: "Others",
                },
              ]}
              placeholder={{ label: i18n.t("signUp.step4_q_opt1"), value: "" }}
              style={pickerSelectStyles}
              useNativeAndroidPickerStyle={false}
            />

            {whereHearErr && <Text className="color-red-500 my-1">{i18n.t("signUp.step4_q_err")}</Text>}

            <View className="w-full h-[0.5px] bg-[#E5E6E8] mt-6 mb-1"></View>

            <Text className="text-[#4E5969] text-xs mt-4 mb-1 font-PingFangSC">{i18n.t("signUp.promo_code")}</Text>
            <TextInput
              placeholder={i18n.t("signUp.promo_code_input_msg")}
              className="h-9 w-full bg-[#F2F3F5] color-[#121212] px-4"
              value={promoCode}
              onChange={(e) => {
                setPromoCode(e.target.value);
                debouncedCheckPromoCode(e.target.value);
                // setTimeout(() => {
                //   checkPromoCode(e.target.value);
                // }, 2000);
              }}
            ></TextInput>

            {promoCodeErr && <Text className="color-red-500 my-1">{i18n.t("signUp.promo_code_err_msg")}</Text>}
            {promoCodeValidMsg && <Text className="text-green-500 my-1">{i18n.t("signUp.promo_code_re_msg")}</Text>}

            <View className="w-full h-[0.5px] bg-[#E5E6E8] mt-6 mb-1"></View>

            <Text className="text-xl font-bold text-right mt-4 mb-6">
              {i18n.t("signUp.total")} KRW{signUpAmt}
            </Text>

            <Captcha returnStatus={checkCaptcha} />
            {recaptchaErr && <Text className="color-red-500 my-1">{i18n.t("signUp.captcha_errMsg")}</Text>}
          </>
        )}

        <View className="w-full max-w-2xl md:hidden py-10">
          {step === 1 && (
            <>
              <TouchableOpacity className="w-full h-10 max-h-10 bg-brandgold mt-4" onPress={() => goStep(2)}>
                <Text className="text-white w-full h-10 leading-10 text-center font-normal font-PingFangSC">{i18n.t("signUp.next")}</Text>
              </TouchableOpacity>
            </>
          )}
          {step === 2 && (
            <>
              <TouchableOpacity className="w-full h-10 max-h-10 bg-brandgold mt-4" onPress={() => goStep(3)}>
                <Text className="text-white w-full h-10 leading-10 text-center font-normal font-PingFangSC">{i18n.t("signUp.next")}</Text>
              </TouchableOpacity>
            </>
          )}
          {step === 3 && (
            <>
              <TouchableOpacity className="w-full h-10 max-h-10 bg-brandgold mt-4" onPress={() => goStep(4)}>
                <Text className="text-white w-full h-10 leading-10 text-center font-normal font-PingFangSC">{i18n.t("signUp.next")}</Text>
              </TouchableOpacity>
            </>
          )}
          {step === 4 && (
            <>
              <TouchableOpacity className="w-full h-10 max-h-10 bg-brandgold mt-4" onPress={() => submitForm()}>
                <Text className="text-white w-full h-10 leading-10 text-center font-normal font-PingFangSC">{i18n.t("signUp.submitForm")}</Text>
              </TouchableOpacity>
            </>
          )}
        </View>
      </ScrollView>

      <Footer />
      <CookieConsent />
      <Modal animationType="slide" visible={paymentWebview}>
      <Text className="text-center font-medium leading-8 text-base text-red-500">Note: Please do not refresh or close your browser until your payment has been confirmed.</Text>

        <WebView
          source={{ uri: paymentURL }}
          style={{ marginTop: 32 }}
          onLoad={(syntheticEvent) => {
            const { nativeEvent } = syntheticEvent;
            console.log(nativeEvent);
            //this.url = nativeEvent.url;
          }}
          onNavigationStateChange={(navState) => {
            console.log(navState);
          }}
          scalesPageToFit={false}
          onMessage={(event) => {
            console.log(event);

            if (event.nativeEvent.data.paymentResult) {
              let resCode = event.nativeEvent.data.paymentResult.respCode;
              console.log(resCode);

              if (resCode == "2000") {
                setTimeout(() => {
                  setIsLoading(true);
                  setPaymentWebview(false);
                  CheckPayment();
                  console.log("Payment success");
                }, 3000);
              }
            }
          }}
        />
      </Modal>

      <Modal animationType="slide" visible={calendarView} transparent={true}>
        {/* <Calendar hideArrows={false} disableMonthChange={false} onDayPress={day => {console.log('selected day', day);}}></Calendar> */}
        <View className="top-0 right-0 bottom-0 left-0 justify-center items-center bg-[#00000050]" style={{ position: "fixed" }}>
          <View className="w-11/12 max-w-lg min-h-[300px] bg-white px-6 py-3 relative">
            <CalendarPicker onDateChange={onDateChange} initialDate={moment().subtract(18, "years")} width={350} height={400} />
          </View>
        </View>
      </Modal>
    </SafeAreaView>
  );
};

const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    color: "#86909C",
    height: 34,
    width: "100%",
    borderWidth: 1,
    borderColor: "#F2F3F5",
    backgroundColor: "#F2F3F5",
    paddingHorizontal: 15,
    borderRadius: 0,
  },
  inputAndroid: {
    color: "#86909C",
    height: 34,
    width: "100%",
    borderWidth: 1,
    borderColor: "#F2F3F5",
    backgroundColor: "#F2F3F5",
    paddingHorizontal: 15,
    borderRadius: 0,
  },
  inputWeb: {
    color: "#86909C",
    height: 34,
    width: "100%",
    borderWidth: 1,
    borderColor: "#F2F3F5",
    backgroundColor: "#F2F3F5",
    paddingHorizontal: 15,
    borderRadius: 0,
  },
});

export default SignUp;
