export default {
  login: {
    login: "LOGIN",
    email: "Email",
    mobile: "Mobile",
    mobile2: "MOBILE NO",
    password: "PASSWORD",
    errMsg1: "Please enter email & password",
    errMsg2: "Please enter mobile & password",
    errMsg3: "Your account isn't this region",
    errMsg4: "Incorrect login or password",
    errMsg5: "Incorrect login mobile or password",
    errMsg6: "Your registration is not complete until payment is received.",
    forgotpw: "Forgot password?",
    register: "Register",
    txt: "Not an Imperial Treasure Club member yet? Check out our ",
    txt_2: "Membership Privileges.",
  },
  header: {
    home: "HOME",
    eStore: "E-STORE",
    PRivileges: "PRivileges",
    register: "register",
    highlights: "highlights",
    faq: "faq",
    tnp: "terms & privacy",
    profile: "Profile",
  },
  footer: {
    aboutUs: "About Us",
    eStore: "E-STORE",
    faq: "FAQs",
    tnp: "Terms & Privacy",
    pr: "Participating Restaurant",
    profile: "Profile",
    contactUs: "Contact Us",
    copyright: "Copyright © 2023 Imperial Treasure Restaurant Group Pte Ltd.",
  },
  signUp: {
    signUp: "Membership Registration",
    title: "Register as a member of Imperial Treasure Club",
    mustFill: '"*" denotes required fields',
    step1: "Account Details",
    step2: "Security Information",
    step3: "Subscriptions",
    step4: "Reference",
    salutation: "Salutation",
    salutation_opt1: "Please select your salutation",
    salutation_opt2: "Mr",
    salutation_opt3: "Mrs",
    salutation_opt4: "Mdm",
    salutation_opt5: "Miss",
    salutation_opt6: "Dr",
    salutation_errMsg: "Please select salutation",
    firstname: "Given Name",
    firstname_errMsg: "Please fill in your given name",
    lastname: "Family Name",
    lastname_errMsg: "Please fill in your family name",
    birth: "Birth Date",
    birth_errMsg: "Please select date of birth",
    birth_errMsg2: "You must 18 years old or older",
    mobile: "Mobile No.",
    mobile_errMsg: "Please enter valid phone",
    mobile_errMsg2: "Phone already registered",
    emailAddress: "Email Address",
    emailAddress_errMsg: "Please enter valid email",
    emailAddress_errMsg2: "Email already registered",
    confirmEmailAddress: "Confirm Email Address",
    confirmEmailAddress_errMsg: "Please confirm email",
    gender: "Gender",
    gender_opt1: "Please select gender",
    gender_opt2: "Male",
    gender_opt3: "Female",
    gender_errMsg: "Please select gender",
    language_preference: "Language Preference",
    language_preference_opt1: "Please select your language preference",
    language_preference_opt2: "English",
    language_preference_opt3: "简体中文",
    language_preference_opt4: "한국인",
    language_preference_errMsg: "Please select language preference",
    password_format: "Password must contain at least 8 alphanumeric characters and with at least one uppercase and one special character (?!@$%&*)",
    password: "*Password",
    password_errMsg: "Please enter password",
    confirm_password: "*Confirm Password",
    confirm_password_errMsg: "Please Enter password again",
    step3_consent_opt: "I agree to all <link>terms and conditions<link> below",
    step3_consent_txt: "• I agree to the <link>Terms and Condition<link>.\n• I agree to the collection and use of my personal information\n• I agree to the collection and use of my personal information for marketing purposes\n• I agree to the transfer of my personal information to third parties either in Korea or overseas",
    tnc_errMsg: "Please agree to Terms & Conditions to proceed",
    step3_title: "By checking the boxes below, I give my consent for Imperial Treasure Restaurant Group Pte Ltd ('ITRG') to collect, use, store and/or disclose any personal data provided by me and/or data related with my use of Imperial Treasure Club Membership (hereinafter the 'Data') for Imperial Treasure to share my data with any of its related companies or other third parties so that I may enjoy fully the benefits arising from my membership or loyalty programme, including benefits arising from Imperial Treasure's relationship with service providers outside of Singapore.",
    step3_q: "I agree to receive marketing messages from Imperial Treasure (Optional)",
    step3_q_opt1: "By Email",
    step3_q_opt2: "By Mobile",
    step4_q_err: "Please select where did you hear about our programme",
    step4_q: "Where did you hear about our programme?",
    step4_q_opt1: "Select a reference",
    step4_q_opt2: "Paradise City, Fine Chinese Cuisine",
    step4_q_opt3: "Paradise City Plaza, Dim Sum & Steam Boat",
    step4_q_opt16: "Word of Mouth",
    step4_q_opt17: "Social Media",
    step4_q_opt18: "Others",
    promo_code: "Promo Code",
    promo_code_input_msg: "Please enter a valid promo code (if any)",
    promo_code_err_msg: "Please enter valid promo code.",
    promo_code_re_msg: "Promo code is valid!",
    total: "Total:",
    refresh: "Refresh",
    captcha: "Captcha",
    captcha_errMsg: "Captcha incorrect",
    cancel: "Cancel",
    next: "Next",
    back: "Back",
    submitForm: "Proceed to payment",
  },
  aboutUs: {
    aboutUs: "ABOUT US",
    txt1: "The Imperial Treasure Restaurant Group",
    txt2: "With our vast array of iconic Chinese delicacies and our commitment to impeccable service, Imperial Treasure has become synonymous with authentic fine Chinese cuisine. Since the establishment of our first restaurant in 2004, Imperial Treasure Restaurant Group has won the hearts of gourmands and casual diners alike.",
    txt3: "Today, the Group has more than 20 restaurants under its name, as well as over 50 accolades – including: 2-Michelin Stars in the Michelin Guide Shanghai (2017 – 2023) for Imperial Treasure Fine Chinese Cuisine (Yi Feng Galleria), 2-Michelin stars in the Michelin Guide Guangzhou  (2020 – 2022) and 1-Michelin star in the Michelin Guide Guangzhou  (2019) for Imperial Treasure Fine Chinese Cuisine (IGC), 1-Michelin star in the Michelin Guide Singapore (2017 – 2019,  2021 – 2022) for Imperial Treasure Fine Teochew Cuisine (ION Orchard), 1-Michelin star in the Michelin Guide Hong Kong Macau (2018 – 2022) for Imperial Treasure Fine Chinese Cuisine (Tsim Sha Tsui), 1-Michelin star in the Michelin Guide Guangzhou (2021 – 2022) for Imperial Treasure Fine Teochew Cuisine (Taikoo Hui), Michelin Plate by Michelin Guide Guangzhou (2018, 2020), Michelin Plate by Michelin Guide France (2020), Michelin Plate by Michelin Guide Singapore (2018 – 2019), Michelin Plate by Michelin Guide Great Britain & Ireland (2019), Two-Diamond in Meituan-Dianping’s Black Pearl Guide (2018), Top Chinese Restaurants of the World by Ch’ng Poh Tiong, Asia's 50 Best Restaurants (2013 – 2015).",
    txt4: "Imperial Treasure has restaurants in Singapore, Shanghai, Guangzhou, Hong Kong, Incheon, London and Paris.",
  },
  faqs: {
    faqs: "FAQs",
    general: "General",
    general_q1: "What is the Imperial Treasure Club?",
    general_q1_opt1: "Imperial Treasure Club is a cardless loyalty programme that allows members to enjoy exclusive privileges and rewards whenever they dine at any participating Imperial Treasure restaurants.",
    general_q2: "How do I become a member of Imperial Treasure Club?",
    general_q2_opt1: "You may register online at members.imperialtreasure.co.kr.",
    general_q3: "Which is the participating restaurant in Korea?",
    general_q3_opt1: "Membership privileges are only valid in Korea at participating Imperial Treasure restaurants.",
    general_q3_opt2: "Imperial Treasure Fine Chinese Cuisine",
    general_q3_opt2_1: "Paradise City, Main Building 1F | Tel: (82) 32 729 2227",
    general_q3_opt3: "Imperial Treasure Dim Sum & Steamboat Restaurant",
    general_q3_opt3_1: "Paradise City, Main Building 1F | Tel: (82) 32 729 5155",
    general_q4: "What is the validity of the membership?",
    general_q4_opt1: "The membership is valid for 1 year from the date of sign up. E.g. If the registration is made on 17 August 2023, the membership will expire on 31 August 2024.",
    general_q5: "Where can I check the expiry date of my membership?",
    general_q5_opt1: "You may log in to the members' portal to check the expiry date of your membership.",
    general_q6: "What is the minimum age that I can apply as an Imperial Treasure Club member?",
    general_q6_opt1: "Customers need to be above 18 years of age to apply as an Imperial Treasure Club member.",
    general_q7: "Why should I register my details at Imperial Treasure Club?",
    general_q7_opt1: "Registering your details at http://members.imperialtreasure.co.kr is to activate your account. In addition, you will also receive the latest news on upcoming members’ promotions, rewards, invitations to events and more!",
    general_q8: "Where do I update my personal particulars?",
    general_q8_opt1: "To update or view your personal particulars, please log in to your account via the members’ portal at members.imperialtreasure.co.kr and click on “Profile”. For assistance to update your date of birth, kindly write in to kr.club@imperialtreasure.com.",
    general_q9: "How do I check my i-dollars, e-vouchers and other rewards?",
    general_q9_opt1: "You may log in to members.imperialtreasure.co.kr to check your membership privileges and rewards balance.",
    general_q10: "What if I forgotten my password?",
    general_q10_opt1: "To reset your password, visit the members’ portal at members.imperialtreasure.co.kr and click on “Forgot Password”. Follow the on-screen instructions to reset your password.",
    general_q11: "What should I do if I have questions regarding my membership account?",
    general_q11_opt1: "If you have any questions regarding your membership account, please email us at kr.club@imperialtreasure.com.",
    general_q12: "Is my Imperial Treasure Club membership transferrable?",
    general_q12_opt1: "No. Membership is not transferrable.",
    general_q13: "How do I terminate my membership?",
    general_q13_opt1: "Member may choose to terminate their membership at any time before the expiry date by writing in to kr.club@imperialtreasure.com. Kindly be advised that there will be no refunds made and/or early issuance of any unused e-vouchers at the point of termination. Alternatively, your membership will automatically expire upon non-renewal of membership, and all balance i-dollars and/or unused e-vouchers will be deemed forfeited.",
    membershipTypes: "Membership Types",
    membershipTypes_q1: "What are the membership tiers?",
    membershipTypes_q1_opt1: "There are 3 membership tiers - Silver, Gold and Diamond.",
    membershipTypes_q2: "How to be a Silver / Gold / Diamond member?",
    membershipTypes_q2_opt1: "To become a Silver member, it is by invitation and you can sign up via online.",
    membershipTypes_q2_opt2: "To become a Gold member, existing members need to accumulate the qualifying spend of KRW10m* and above within the membership year to qualify.",
    membershipTypes_q2_opt3: "To become a Diamond member, existing members need to accumulate the qualifying spend of KRW25m* and above within the membership year to qualify.",
    membershipTypes_q2_opt4: "*Qualifying spend is calculated before service charge.",
    membershipTypes_q3: "How to retain my membership status?",
    membershipTypes_q3_opt1: "To maintain as a Gold member, simply accumulate the qualifying spend of KRW10m * within the membership year to renew your membership status.",
    membershipTypes_q3_opt2: "To maintain as a Diamond member, simply accumulate the qualifying spend of KRW25m * within the membership year to renew your membership status.",
    membershipTypes_q3_opt3: "*Qualifying spend excludes VAT, discount, e-vouchers redemption, and festive items.",
    membership: "Membership Privileges & Rewards",
    membership_q1: "What are the membership privileges?",
    sliver: "Sliver",
    gold: "Gold",
    diamond: "Diamond",
    complimentary_treats: "Complimentary Treats",
    complimentary_treats_1_1: "1 X Peking Duck",
    complimentary_treats_1_2: "-",
    complimentary_treats_1_3: "Yes",
    complimentary_treats_1_4: "Yes",
    complimentary_treats_2_1: "1 X 3-pc Dim Sum Set (Seasonal)",
    complimentary_treats_2_2: "-",
    complimentary_treats_2_3: "Yes",
    complimentary_treats_2_4: "-",
    complimentary_treats_3_1: "1 X Sauteed Prawns in Singapore Style Chili Sauce ",
    complimentary_treats_3_2: "-",
    complimentary_treats_3_3: "Yes",
    complimentary_treats_3_4: "-",
    complimentary_treats_4_1: "1X KRW250k e-voucher",
    complimentary_treats_4_2: "-",
    complimentary_treats_4_3: "Yes",
    complimentary_treats_4_4: "-",
    complimentary_treats_5_1: "1 X Champagne",
    complimentary_treats_5_2: "-",
    complimentary_treats_5_3: "-",
    complimentary_treats_5_4: "Yes",
    complimentary_treats_6_1: "1 X Turbot Fish",
    complimentary_treats_6_2: "-",
    complimentary_treats_6_3: "-",
    complimentary_treats_6_4: "Yes",
    complimentary_treats_7_1: "1 X KRW500k e-voucher",
    complimentary_treats_7_2: "-",
    complimentary_treats_7_3: "-",
    complimentary_treats_7_4: "Yes",
    membership_q2: "How do I know the expiry of my privileges?",
    membership_q2_opt1: "To check the expiry dates of your privileges, simply log in to the members' portal members.imperialtreasure.co.kr and view the details reflected under the 'Vouchers' section.",
    membership_q3: "How do I identify myself as an Imperial Treasure Club member?",
    membership_q3_1_1: "To identify yourself as a member, simply provide your mobile number to our service staff.",
    membership_q4: "How do I enjoy my complimentary treats? ",
    membership_q4_1_1: "For redemption of food/wine items, one (01) day advance order is required and available at Imperial Treasure Fine Chinese Cuisine. For redemption of £300/£500 evoucher, simply inform the service staff prior to payment. Member must be physically present (proof of identification required) to redeem any membership privileges.",
    membership_q5: "Can I as a corporate customer enjoy corporate discount with the Imperial Treasure Club membership?",
    membership_q5_opt1: "No, corporate discount is not eligible for use together with the Imperial Treasure Club membership. Corporate customers can only choose either corporate discount or the Imperial Treasure Club membership for each receipt.",
    membership_q6: "Can I enjoy my Imperial Treasure Club membership privileges in another country where there is an Imperial Treasure restaurant?",
    membership_q6_opt1: "For earning of i$, it is allowed in any Imperial Treasure restaurants regardless of the location. The i$ conversion will be based on registered base country and according to currency rate set forth by Imperial Treasure. For redemption of e-vouchers, you can only redeem in the country that you registered the account in.",
    membership_q7: "Is my Imperial Treasure Club membership transferrable?",
    membership_q7_opt1: "No. Membership is not transferrable.",
    earning: "Earning & Redemption of points",
    earning_q1: "How do I earn i$?",
    earning_q1_opt1: "Earn i$1 for every KRW10k* spent at participating restaurants (*excludes VAT, e-vouchers redemption, and purchase of festive items).  Member must be physically present (proof of identification required) at the point of dining. Requests on i$ accumulation for previous or backdated receipts will not be entertained. The system may require up to one (01) working day to reflect the transactions, e-vouchers and i$ earned. Combining multiple membership accounts or splitting the bill to earn i$ is strictly not allowed. Balance i$ accrued shall expire upon expiry of membership. Balance i$ accrued is however granted a grace period of three (03) months upon expiry membership and must be converted to a KRW50k e-voucher within the grace period, otherwise deemed forfeited.",
    earning_q2: "How do I redeem my i$?",
    earning_q2_opt1: "For every i$50 earned, members will automatically receive a KRW50k e-voucher valid for use within the membership year. The KRW50k e-voucher can be used to offset the bill when dining at any participating restaurants. Member must be physically present (proof of identification required) at the point of dining to redeem any membership privileges. Member is required to sign off on the receipt to acknowledge the redemption(s) made. Combining multiple membership accounts or splitting the bill to redeem membership privileges is not allowed.\n(*system automatically converts i$ at a stipulated time daily)",
    earning_q3: "Can I earn i$ or redeem membership privileges for online purchases made at the e-store?",
    earning_q3_opt1: "No. Earning of i$ and/or redeeming of membership privileges are only available for purchases made at participating restaurants, unless otherwise specified.",
    earning_q4: "Will my i$ expire?",
    earning_q4_opt1: "Yes. i$ earned is only valid during the membership period and will expire once the membership expires. Balance i$ will be brought forward for a grace period of three (03) months upon expiry of membership and must be converted to a KRW50k e-voucher within the grace period, otherwise deemed forfeited.",
    earning_q5: "Will my i$ be reflected immediately once I make a purchase?",
    earning_q5_opt1: "Yes. The system will reflect i$ earned instantly but do allow for time lapses that may be caused by any delays in the network during unforeseen circumstances.",
    earning_q6: "Can I use multiple membership accounts to earn i$ and redeem membership privileges?",
    earning_q6_opt1: "No. Combining multiple membership accounts to earn i$ or redeem membership privileges is not allowed.",
    earning_q7: "Can I split my bill to earn i$ and/or redeem membership privileges?",
    earning_q7_opt1: "No. Splitting of bill to earn i$ and/or redeem membership privileges is not allowed.",
    earning_q8: "Can my family and friends earn i$ or redeem membership privileges if I am not present?",
    earning_q8_opt1: "No. Members must be present to enjoy the membership privileges.",
    earning_q9: "Can I earn i$ or redeem membership privileges for orders made via online orders made via third party platforms?",
    earning_q9_opt1: "No. Earning of i$ and redemption of membership privileges are not eligible for online orders made via third party platforms.",
  },
  tnc: {
    title: "Terms And Privacy",
    membership: "Membership Terms And Privacy",
    membership_1_1: "1.1 Introduction",
    membership_1_1_1: 'Imperial Treasure South Korea Co. Ltd and Imperial Treasure F&B Management Pte. Ltd.(“Imperial Treasure”) would like to offer our privileged customers news and information regarding our products and services, including promotions, special offers and discounts.  We are offering you the opportunity to become a member of the Imperial Treasure Club (a "Member") to enjoy special privileges that Imperial Treasure may offer from time to time.',
    membership_1_2: "1.2 Collection and use of personal data",
    membership_1_2_1: "If you become a Member, you will need to provide Imperial Treasure certain personal information (including your name and contact details such as email address and phone number).  You will need to provide us with your mobile number for us to identify you. Your mobile number will be your username or unique identifier as a member of Imperial Treasure Club. Any personal data held by us about you will be held in accordance with our Privacy Notice.",
    membership_1_3: "1.3 Direct marketing",
    membership_1_3_1: 'By clicking on the "SUBMIT" button below, you agree that Imperial Treasure, our affiliates, related entities, and third parties acting on our behalf ("Business Partners"), may collect, use and disclose your personal data, which you have provided at the member\'s portal at https://members.imperialtreasure.com.kr (the "Member\'s Portal"), for providing marketing material that you have agreed to receive, in accordance with the Personal Information Protection Act (“PIPA”) and our Privacy Notice. Please note that marketing material may be sent directly by our Business Partners, and we may disclose your personal data to them for that purpose.',
    membership_1_4: "1.4 Membership Tiers",
    membership_1_4_1: "1.4.1 Membership registration, i-dollars, and Cash e-Vouchers",
    membership_1_4_1_1: "Where you sign up to become a Member, you will be required to provide your mobile number, among other information we may require from you, in order to sign up for the membership. This may be required online or by our restaurant staff depending on the circumstances.",
    membership_1_4_1_2: "Membership is strictly non-transferable and shall only be enjoyed by the Member in person.",
    membership_1_4_1_3: "As a Member, you earn one (01) i-dollar at the rate specified on the membership programme website [Link:Members.imperialtreasure.co.kr/benefits] for the country in which you signed up, when you spend at any participating Imperial Treasure restaurants (excluding service charge, tax, Cash e-vouchers used, and the purchase and use of Imperial Treasure gift vouchers). Please refer to the Member’s Portal for a list of participating Imperial Treasure restaurants. Member must be physically present at the point of dining and provide the registered mobile number to the service staff in order to earn i-dollars. Earning of i-dollars must be made at the point of transaction. Requests on i-dollar accumulation for previous or backdated receipts will not be entertained. The system may require up to one (01) working day to reflect the transactions, Cash e-vouchers and i-dollar earned. I-dollars accrued shall expire upon expiry of membership. Any i-dollar accrued is however granted a three (03) months grace period and must be converted to Cash e-Vouchers within this grace period, failing which such i-dollar shall be deemed forfeited. Transactions made via third party vendors are not eligible for earning of i-dollars.",
    membership_1_4_1_4: "Earned i-dollars are converted in the Member Portal into Imperial Treasure e-vouchers of a specified value (“Cash e-Vouchers”) at the rate specified on the membership programme website [Link:Members.imperialtreasure.co.kr/benefits] for the country in which you signed up. Each Cash e-Voucher is valid for twelve (12) months from date of issue and valid for use within the membership period only. The Cash e-Voucher can be used to offset the bill when dining at any participating Imperial Treasure restaurants, subject to Imperial Treasure’s sole discretion and any other terms and conditions stated at [https://members.imperialtreasure.co.kr]. Neither i-dollars nor Cash e-Vouchers have any cash value and cannot be converted or redeemed for cash.",
    membership_1_4_2: "1.4.2 Membership Tiers",
    membership_1_4_2_1: "Unless otherwise determined by Imperial Treasure in its sole discretion, Silver Tier status is conferred on Members when they are invited to first join the membership programme and is valid for the period specified on the membership programme website [Link:https://members.imperialtreasure.co.kr] for the country in which you signed up. At the end of this period, if the Member has not been upgraded to the Gold Tier or Diamond Tier, the Member may be required to pay such renewal fee or meet the relevant renewal requirements as may be prescribed by Imperial Treasure in its sole discretion on the membership website [Link: https://members.imperialtreasure.co.kr] for the country in which you signed up. Imperial Treasure will give a reasonable advance notice to relevant Members thirty (30) days prior to the end of the membership programme period.",
    membership_1_4_2_2: "Gold Tier status is conferred on Members who spend at least the amount set out for Gold Tier on the membership programme website [Link: https://members.imperialtreasure.co.kr] for the country in which you signed up, at participating Imperial Treasure restaurants within twelve (12) months. Gold Tier status is valid for twelve (12) months from the date the Member qualifies; after that, annual requalification is required. Upon expiration of your membership tenure as a Gold Tier member, and if you have not met the minimum spend requirement to be retained in the tier, you shall be downgraded to Silver Tier status.",
    membership_1_4_2_3: "Diamond Tier status is conferred on Members who spend at least the amount set out for Diamond Tier on the membership programme website [Link: https://members.imperialtreasure.co.kr] for the country in which you signed up, at participating Imperial Treasure restaurants within twelve (12) months. Diamond Tier status is valid for twelve (12) months from the date the Member qualifies; after that, annual requalification is required. Upon expiration of your membership tenure as a Diamond Tier member, and if you have not met the minimum spend requirement to be retained in the tier, you shall be downgraded to Gold Tier or Silver Tier status accordingly.",
    membership_1_4_2_4: "Imperial Treasure may, in its sole discretion, alter, limit, or modify the Silver Tier, Gold Tier and/or Diamond Tier program rules, regulations, benefits, eligibility for membership, or any other feature of the Silver Tier, Gold Tier and/or Diamond Tier programme and/or may terminate the Silver Tier, Gold Tier and/or Diamond Tier programme at any time in its sole discretion, by giving reasonable advance notice of at least [30] days on the membership programme website and/or by email. Please check the membership programme website regularly for updates.",
    membership_1_4_3: "1.4.3 Membership Tier Rewards",
    membership_1_4_3_1: "Each membership tier also entitles Members to certain rewards and privileges, which may include other e-vouchers, free gifts and/or other dining benefits (“Rewards”). The current tier privileges are as set out in the membership programme website [Link: https://members.imperialtreasure.co.kr]. The validity and terms and conditions of such Rewards are as set out in [Link: Members.imperialtreasure.co.kr/tnc], which may be amended by Imperial Treasure at any time in its sole discretion, by giving reasonable notice on the membership programme website and/or by email. Rewards are always subject to availability. Imperial Treasure reserves the right to replace a Reward with another Reward of equivalent or similar value. Rewards do not have any cash value and cannot be converted or redeemed for cash.",
    membership_1_4_4: "1.4.4 Redemption of Rewards and Cash e-Vouchers",
    membership_1_4_4_1: "Member must be physically present (proof of identification required) at the point of dining in order to redeem any membership privileges. Member is required to sign off on the receipt to acknowledge the redemption(s) made. Redemption of Rewards are subject to prevailing local law requirements.",
    membership_1_4_4_2: "Combining multiple membership accounts, combining bills or splitting the bills to earn i-dollars and/or redeem Rewards and membership privileges is strictly not allowed. Membership must be active at the point of redemption.",
    membership_1_5: "1.5 Amending personal data and terminating membership",
    membership_1_5_1: "You may amend your personal data or marketing preferences at any time via our Member’s Portal. You will need to inform us in writing at kr.club@imperialtreasure.com should you choose to terminate your membership. Please allow a few working days for changes to take effect. Kindly be advised that there will be no refunds made and/or early issuance of any unused Cash e-vouchers at the point of termination. Alternatively, your membership will automatically expire upon non-renewal of membership, and all balance i-dollars and/or unused Cash e-vouchers will be deemed forfeited.",
    membership_1_6: "1.6 Termination and cancellation",
    membership_1_6_1: "We reserve the right to terminate the Member's membership if the membership account is found to be shared with another non-Member. We reserve the right to terminate your membership, in our absolute discretion, should you abuse any of the conditions and privileges bestowed on you as a Member.",
    membership_2: "2. Law and forum",
    membership_2_1: "These Terms, including all policies and additional terms referenced herein (“Membership Agreement”),  are governed by and construed in accordance with the laws of South Korea.",
    membership_2_2: "Any dispute or claim arising out of or in connection with this Membership Agreement, (including non-contractual disputes or claims) shall be subject to the exclusive jurisdiction of South Korean courts.",
    membership_3: "3. Consent",
    membership_3_agree_all_terms: "□ I agree to all terms and conditions below",
    membership_3_agree_tnc: "□ I agree to the Terms and Conditions",
    membership_3_agree_tnc_link: "[Link: Members.imperialtreasure.co.kr/tnc ] (Required)",
    membership_3_agree_personal_info: "□ I agree to the collection and use of my personal information (Required)",
    membership_3_scroll_box: "[SCROLL BOX]",
    membership_3_purpose_of_collection: "Purpose of Collection and Use",
    membership_3_purpose_of_collection_1: "For restaurant and membership related processing",
    membership_3_personal_info: "Personal Information to be Collected",
    membership_3_personal_info_1: "Name, DOB, email, mobile",
    membership_3_retention_period: "Retention period",
    membership_3_retention_period_1: "Until membership cancellation",
    membership_3_agree_personal_info_mkt: "□ I agree to the collection and use of my personal information for marketing purposes (Optional)",
    membership_3_purpose_of_collection_2: "To provide you with marketing and promotional material and other information about our products (including promotions and special offers), as well as other products and services provided by our affiliates and partners",
    membership_3_personal_info_1: "Name, DOB, email, mobile",
    membership_3_retention_period_1: "Until membership cancellation",
    membership_3_agree_personal_info_third_parties: "□ I agree to the transfer of my personal information to third parties either in Korea or overseas (Optional)",
    membership_3_personal_data_trf: "1. For personal data transfer within Korea",
    membership_3_transferee: "Transferee",
    membership_3_transferee_1: "Imperial Treasure F&B Management Pte. Ltd.",
    membership_3_personal_info_tra: "Personal Information to be Transferred",
    membership_3_personal_info_tra_1: "Name, DOB, email, mobile, membership tier",
    membership_3_purpose_of_use: "Purpose of Use of Personal Information by the Transferee",
    membership_3_purpose_of_use_1: "For restaurant and membership related processing",
    membership_3_transferee_retention_period: "Transferee’s Retention Period",
    membership_3_transferee_retention_period_1: "Until membership cancellation",
    membership_3_personal_data_trf_outside: "2. For personal data transfer outside of Korea",
    membership_3_personal_data_trf_outside_transferee: "Transferee",
    membership_3_personal_data_trf_outside_transferee_1_1: "Xgate Pte Ltd data.privacy@xgate.com",
    membership_3_personal_data_trf_outside_transferee_1_2: "- Imperial Treasure Restaurant Group Pte Ltd \n- Imperial Treasure F & B Management Pte Ltd \n-  Imperial Treasure (Teochew Cuisine) Pte Ltd \n- Imperial Treasure Nan Bei Restaurant Pte Ltd \n- Imperial Treasure Noodle House Pte Ltd \n- Imperial Treasure Cantonese Cuisine Pte Ltd \n- Imperial Treasure Fine Dining Restaurant Pte Ltd \n- Imperial Treasure Windows of Hong Kong Pte Ltd \n- Imperial Treasure Steamboat Pte Ltd \n- Imperial Treasure Shanghai Cuisine Pte Ltd \n- Imperial Treasure F&B Shanghai Co., Ltd \n- Imperial Treasure Xin Zhou F&B Management Co Ltd \n- Imperial Treasure F&B Management Shanghai Co., Ltd \n- Imperial Treasure (Guangzhou) Co., Ltd. \n- Imperial Treasure (International) Co., Ltd. \n- Imperial Treasure South Korea Co., Ltd \n- Imperial Treasure F & B Management Pte Ltd \n- Imperial Treasure (UK) Co., Ltd \n- Imperial Treasure (France) \n- dpo@imperialtreasure.com",
    membership_3_personal_data_trf_outside_time_and_method: "Time and Method of Transfer",
    membership_3_personal_data_trf_outside_time_and_method_1_1: "Real-time / Online transfer",
    membership_3_personal_data_trf_outside_time_and_method_1_2: "Real-time / Online transfer",
    membership_3_personal_data_trf_outside_personal_info_tra: "Personal Information to be Transferred",
    membership_3_personal_data_trf_outside_personal_info_tra_1_1: "Name, DOB, email, mobile, membership tier",
    membership_3_personal_data_trf_outside_personal_info_tra_1_2: "Name, DOB, email, mobile, membership tier",
    membership_3_personal_data_trf_outside_purpose_of_use: "Purpose of Use of Personal Information by the Transferee",
    membership_3_personal_data_trf_outside_purpose_of_use_1_1: "Membership related benefits",
    membership_3_personal_data_trf_outside_purpose_of_use_1_2: "Membership related benefits",
    membership_3_personal_data_trf_outside_transferee_retention_period: "Transferee’s Retention Period",
    membership_3_personal_data_trf_outside_transferee_retention_period_1_1: "Until membership cancellation",
    membership_3_personal_data_trf_outside_transferee_retention_period_1_2: "Until membership cancellation",
    membership_3_agree_receive_mkt_msg: "□ I agree to receive marketing messages from Imperial Treasure (Optional)",
    privacy: "Privacy Notice",
    privacy_1: "1. INTRODUCTION",
    privacy_1_1: 'Imperial Treasure South Korea Co. Ltd (collectively referred as "Imperial Treasure", "us", "we") is committed to protect the privacy of visitors to this website (the "Website"). This Privacy Notice sets out how Imperial Treasure collects, uses or discloses personal data that you have provided to us through the Website. By visiting the Website you are accepting and consenting to the collection, use and disclosure of personal data described in this Privacy Notice. If you do not agree with this Privacy Notice, please do not use the Website. This Privacy Notice does not apply to other websites to which we link ("Third Party Website" or "Third Party Websites").',
    privacy_1_2: "This Privacy Notice also describes your data protection rights, including a right to object to some of the processing which Imperial Treasure carries out. More information about your rights, and how to exercise them, is set out in the following section: “9. What rights do I have”.",
    privacy_1_3: 'Maintaining the privacy of our customers’ information is important to us. We do not disclose rent or sell our customers’ personal data to third parties (except as described in "6. Disclosures of personal data to third parties").',
    privacy_2: "2. COLLECTION AND USE OF PERSONAL DATA",
    privacy_2_1: "We collect the following personal data when you register as a user of the Website and make purchases through the Website:",
    privacy_2_1_1: "email address",
    privacy_2_1_2: "your name, address and phone number (including delivery addresses if different);",
    privacy_2_1_3: "billing address, including your name, address and phone number; and",
    privacy_2_1_4: "any other personal data provided to us when you submit any comment, question or feedback to us, request services from us, or disclose your personal data to us in any way",
    privacy_2_2: "We use your personal data for the following purposes:",
    privacy_2_2_1: "To fulfil a contract, or take steps linked to a contract: this is relevant where you make a purchase from us. This includes:",
    privacy_2_2_1_1: "to fulfil your order and deliver it to you;",
    privacy_2_2_1_2: "to facilitate and track your purchases from us (including in our restaurants or via our websites); and",
    privacy_2_2_1_3: "to provide you with service support;",
    privacy_2_2_2: "As required by Imperial Treasure to conduct our business and pursue our legitimate interest, in particular:",
    privacy_2_2_2_1: "to facilitate your registration as a user of the Website;",
    privacy_2_2_2_2: "to use information you provide to provide products and services you have requested;",
    privacy_2_2_2_3: "(if you use a card payment for any purchase on the Website) we use third parties to check the validity of the sort code, account number and card number you submit in order to prevent fraud; and;",
    privacy_2_2_2_4: "to respond to comments, queries and feedback.",
    privacy_2_2_3: "Where you give us consent:",
    privacy_2_2_3_1: "we provide you with marketing and promotional material and other information about our products (including promotions and special offers), as well as other products and services provided by our affiliates and partners and",
    privacy_2_2_3_2: "on other occasions where we ask you for consent, we will use the data for the purposes which we explain at that time.",
    privacy_2_2_4: "For purposes which are required by law: in response to requests by government or law enforcement authorities conducting an investigation.",
    privacy_2_3: "The provision of your name, family name, contact number, date of birth, country code and email address are mandatory so that we can contact you and fulfil any of your order(s): if any required data is not provided, then we will not be able to fulfil your order(s). All other provision of your information is optional.",
    privacy_2_4: "Additionally, through the Website, we may gather certain aggregated and anonymous information about the use of the Website, such as how frequently certain areas of the Website are visited, including through the use of cookies, web beacons, Google Analytics and other technologies.",
    privacy_2_5: "We may use cookies, web beacons, Google Analytics and other technologies for the following purposes:",
    privacy_2_5_1: "to identify the causes of problems arising at web servers and to resolve these problems;",
    privacy_2_5_2: "to improve the contents of the Website;",
    privacy_2_5_3: "to customize the content of the Website and our emails to suit your individual interests or purposes and selectively display best-matched pages to you, including tracking your past purchases in order to provide you with new services or special promotions;",
    privacy_2_5_4: "to utilize your browsing history on the Website for demographic research; and",
    privacy_2_5_5: "to obtain static data of web visitors and aggregated website visitation statistics which are anonymous in nature.",
    privacy_2_6: "You may reject our use of cookies by configuring your browser to disable the use of cookies. However, you may no longer be able to receive services or personalized features of the Website which rely on the use of cookies.",
    privacy_3: "3. SENDING OF EMAILS TO MEMBERS",
    privacy_3_1: "Genuine emails from Imperial Treasure always will come from an address ending @imperialtreasure.com. Do not open any attachments or click any links from suspicious e-mails. If you wish to report a suspicious email claiming to be from Imperial Treasure that you believe is a forgery, you may email to kr.marketing@imperialtreasure.com",
    privacy_4: "4. RELYING ON OUR LEGITIMATE INTERESTS",
    privacy_4_1: "We have carried out balancing tests for all the data processing we carry out on the basis of our legitimate interests, which we have described above. You can obtain information on any of our balancing tests by contacting us using the details set out later in this notice.",
    privacy_5: "5. WITHDRAWING CONSENT OR OTHERWISE OBJECTING TO DIRECT MARKETING",
    privacy_5_1: "Wherever we rely on your consent, you will always be able to withdraw that consent, although we may have other legal grounds for processing your data for other purposes, such as those set out above. In some cases, we can send you direct marketing without your consent, where we rely on our legitimate interests.  You have an absolute right to opt-out of direct marketing, or profiling we carry out for direct marketing, at any time. You can do this by following the instructions in the communication where this is an electronic message, or by contacting us using the details set out below.",
    privacy_6: "6. DISCLOSURES OF PERSONAL DATA TO THIRD PARTIES",
    privacy_6_1: "Your personal data may also be transferred out of South Korea from time to time. Such transfers will be carried out in accordance with the PIPA. We will not provide your personal data to a third party without your prior consent.",
    privacy_6_2: "List of third parties to which personal data is provided:",
    privacy_6_2_a: "1. For personal data transfer within Korea",
    privacy_6_2_1: "Transferee",
    privacy_6_2_1_1: "Imperial Treasure F&B Management Pte. Ltd.",
    privacy_6_2_2: "Personal Information to be Transferred",
    privacy_6_2_2_1: "Name, DOB, email, mobile",
    privacy_6_2_3: "Purpose of Use of Personal Information by the Transferee",
    privacy_6_2_3_1: "For restaurant and membership related processing",
    privacy_6_2_4: "Transferee’s Retention Period",
    privacy_6_2_4_1: "Until membership cancellation",
    privacy_6_3_a: "2. For personal data transfer outside of Korea",
    privacy_6_3_1: "Name of Transferee\n(Privacy Contact)",
    privacy_6_3_1_1: "Xgate Pte Ltd\ndata.privacy@xgate.com",
    privacy_6_3_1_2: "- Imperial Treasure Restaurant Group Pte Ltd \n- Imperial Treasure F & B Management Pte Ltd\n- Imperial Treasure (Teochew Cuisine) Pte Ltd\n- Imperial Treasure Nan Bei Restaurant Pte Ltd\n- Imperial Treasure Noodle House Pte Ltd\n- Imperial Treasure Cantonese Cuisine Pte Ltd\n- Imperial Treasure Fine Dining Restaurant Pte Ltd\n- Imperial Treasure Windows of Hong Kong Pte Ltd\n- Imperial Treasure Steamboat Pte Ltd\n- Imperial Treasure Shanghai Cuisine Pte Ltd\n- Imperial Treasure F&B Shanghai Co., Ltd\n- Imperial Treasure Xin Zhou F&B Management Co Ltd\n- Imperial Treasure F&B Management Shanghai Co., Ltd\n- Imperial Treasure (Guangzhou) Co., Ltd.\n- Imperial Treasure (International) Co., Ltd.\n- Imperial Treasure South Korea Co., Ltd\n- Imperial Treasure F & B Management Pte Ltd\n- Imperial Treasure (UK) Co., Ltd",
    privacy_6_3_2: "Time and Method of Transfer",
    privacy_6_3_2_1: "Real-time / Online transfer",
    privacy_6_3_2_2: "Real-time / Online transfer",
    privacy_6_3_3: "Personal Information to be Transferred",
    privacy_6_3_3_1: "Name, DOB, email, mobile, membership tier",
    privacy_6_3_3_2: "Name, DOB, email, mobile, membership tier",
    privacy_6_3_4: "Purpose of Use of Personal Information by the Transferee",
    privacy_6_3_4_1: "Membership related benefits",
    privacy_6_3_4_2: "Membership related benefits",
    privacy_6_3_5: "Transferee’s Retention Period",
    privacy_6_3_5_1: "Until membership cancellation",
    privacy_6_3_5_2: "Until membership cancellation",
    privacy_6_4: "List of entrustees to which personal information is provided:",
    privacy_6_4_a: "1. For personal data transfer within Korea",
    privacy_6_4_1: "Transferee",
    privacy_6_4_1_1: "Imperial Treasure F&B Management Pte. Ltd.",
    privacy_6_4_2: "Personal Information to be Transferred",
    privacy_6_4_2_1: "Name, DOB, email, mobile, membership tier",
    privacy_6_4_3: "Purpose of Use of Personal Information by the Transferee",
    privacy_6_4_3_1: "CRM loyalty programme",
    privacy_6_4_4: "Transferee’s Retention Period",
    privacy_6_4_4_1: "Until membership cancellation",
    privacy_6_5: "Where information is transferred outside the EEA, and where this is to a stakeholder or vendor in a country that is not subject to an adequacy decision by the EU Commission, data is adequately protected by EU Commission approved standard contractual clauses, or a vendor's Processor Binding Corporate Rules.  A copy of the relevant mechanism can be provided for your review on request to our Data Protection Office (please refer to the section below: '12 Contact Us').",
    privacy_7: "7. HOW WE PROTECT YOUR INFORMATION",
    privacy_7_1: "We are committed to protecting the information we receive from you. We adopt reasonable physical, technical and administrative practices to help protect the confidentiality, security and integrity of data, including personal data, stored on our system. We believe the measures we have implemented reduce the likelihood of security problems to a level appropriate to the type of data involved. Although we take reasonable steps to protect the information we receive from you, no computer system is completely secure and we cannot be held liable for any loss you may suffer for unauthorized access or loss of any data provided to the Website.",
    privacy_8: "8. LINKS TO THIRD PARTY SITES",
    privacy_8_1: "The Website may contain links to third party websites and microsites, including third party payment gateways, whose privacy practices may differ from those of ours. If you submit personal data, including payment information, to any of those websites, that personal data is not subject to this Privacy Notice and will be subject to the third party's privacy practices instead.",
    privacy_8_2: "We therefore encourage you to review the privacy statement of any website you visit. By clicking on or activating such links and leaving the Website, we can no longer exercise control over any data or information which you give to any other entity after leaving the Website, and any access to such other websites is entirely at your own risk.",
    privacy_9: "9. WHAT RIGHTS DO I HAVE",
    privacy_9_1: "You have the right to ask us for a copy of your personal data; to correct, delete or restrict (stop any active) processing of your personal data; and to obtain the personal data you provide to us for a contract or with your consent in a structured, machine readable format, and to ask us to share (port) this data to another data controller.",
    privacy_9_2: "In addition, you can object to the processing of your personal data in some circumstances (in particular, where we don’t have to process the data to meet a contractual or other legal requirement, or where we are using the data for direct marketing).",
    privacy_9_3: "These rights may be limited, for example if fulfilling your request would reveal personal data about another person, where they would infringe the rights of a third party (including our rights) or if you ask us to delete information which we are required by law to keep or have compelling legitimate interests in keeping. Relevant exemptions are included in the applicable laws and regulations. We will inform you of relevant exemptions we rely upon when responding to any request you make.",
    privacy_9_4: "If you are based in the EU and have unresolved concerns, you have the right to complain to an EU data protection authority where you live, work or where you believe a breach may have occurred.",
    privacy_9_5: 'To exercise any of these rights, or to obtain other information, such as a copy of a legitimate interests balancing test, you can get in touch with us – by contacting our Data Protection Manager (See "12 Contact us" below).  To correct your personal data, you can do so by signing into your user account and updating your personal data. We will only disclose personal data in accordance with an access request to the individual who registered his/her personal data with the Website, and only after confirming that the person requesting access is indeed the registered party.',
    privacy_9_6: "You may also contact our Data Protection Manger to withdraw your consent to our use of your personal data.",
    privacy_9_7: "Please allow ten (10) working days for your request to be processed.",
    privacy_10: "10. RETENTION OF INFORMATION",
    privacy_10_1: "Where we process registration data, we do this for as long as you are an active user of the Website and for six (6) years after this.",
    privacy_10_2: "Where we process personal data for marketing purposes or with your consent, we will process such data until you ask us to stop and for a short period after this (to allow us to implement your requests). We also keep a record of the fact that you have asked us not to send you direct marketing or not to process your data so that we can respect your request in the future.",
    privacy_10_3: "Where we process personal data in connection with performing a contract, we keep such data for six (6) years from your last interaction with us.",
    privacy_10_4: "If you withdraw your consent to our collection, use or disclosure of your personal data, we may not be able to provide you with services that require such personal data. Please also note that your personal data may still be retained on our database to the extent permitted by law.",
    privacy_11: "11. PRIVACY POLICY CHANGES",
    privacy_11_1: "If we decide to change our Privacy Notice for the Website, we will post the revised Privacy Notice here and, if appropriate, notify you by email. Please check back frequently to see any updates or changes to our Privacy Notice.",
    privacy_12: "12. CONTACT US",
    privacy_12_1: "If you have any questions about this Privacy Notice, please contact our Data Protection Manager at DPO@imperialtreasure.com.",
  },
  outlets: {
    outlets: "Participating Restaurants",
    restaurant_name: "Restaurant Name",
    address: "Address",
    contact: "Contact",
    email: "Email",
    outlets_1_1: "Imperial Treasure Fine Chinese Cuisine",
    outlets_1_2: "Paradise City, Main Building 1F \n186 Yeongjonghaeannnam-ro, 321 beon-gil, Jung-gu Incheon 22382, Republic of Korea",
    outlets_1_3: "(82) 32 729 2227",
    outlets_1_4: "finechinese_pcity@imperialtreasure.com",
    outlets_2_1: "Imperial Treasure Dim Sum & Steamboat Restaurant",
    outlets_2_2: "Paradise City Plaza, 1F \n186 Yeongjonghaeannam-ro, 321 beon-gil , Jung-gu, Incheon City, 22382, Republic of Korea",
    outlets_2_3: "(82) 32 729 5155",
    outlets_2_4: "dimsumsteamboat_pcity@imperialtreasure.com",
  },
  privilege: {
    title: "PRIVILEGES",
    privilege: "MEMBERSHIP PRIVILEGES",
    privelege_txt: "Earn i$1 with every KRW10k* spent at participating restaurants.\nAccumulate i$50 to receive a KRW50k e-voucher for use at participating restaurants.",
    silver: "SILVER",
    silver_1: "By invitation only",
    gold: "GOLD",
    gold_1: "Upgrade to GOLD tier when you spend KRW10m* and above within the membership year.",
    gold_2: "1 X KRW250K eVoucher",
    gold_3: "Complimentary treats: \n1 x Peking Duck \n1 x 3 Dim Sum (Siew Mai, Har Gow, Prawn Chee Cheong Fun) \n1 X Sauteed Prawns in Singapore Style Chili Sauce",
    diamond: "DIAMOND",
    diamond_1: "Upgrade to DIAMOND tier when you spend KRW25m* and above within the membership year.",
    diamond_2: "1 X KRW500K eVoucher",
    diamond_3: "Complimentary treats: \n1 x Peking Duck \n1 x Champagne \n1 x Turbot Fish \n1 x Triple Combination of BBQ Meat",
    remark: "*Excludes VAT, discount, e-vouchers redemption, and festive items.",
    sign_up_now: "Sign up now",
  },
  resetPassword: {
    title: "Forget Password",
    reset_password: "Reset password",
    email: "*Please enter email",
    email_input: "Please enter email",
    email_errMsg: "Please enter valid email",
    mobile: "*Please enter mobile",
    mobile_errMsg: "Please enter valid phone",
    new_password: "*New password",
    new_password_errMsg1: "Please enter password",
    new_password_errMsg2: "Password must contain at least 8 alphanumeric characters and with at least one uppercase and one special character (?!@$%&*)",
    confirm_password: "*Confirm new password",
    confirm_password_errMsg: "Please confirm password",
    reset_password2: "Reset password",
    has_been_reset: "Password has been reset!",
    login: "Login",
  },
  forgetPassword: {
    title: "Forget Password",
    reset_password: "Reset password",
    forgot_password: "Forgot password?",
    reset_pw_method: "Please select password reset method",
    by_mobile: "By mobile",
    by_email: "By email",
    email: "*Please enter email",
    email_input: "Please enter email",
    email_errMsg: "Please enter valid email",
    mobile: "*Please enter mobile",
    mobile_errMsg: "Please enter valid phone",
    reset_password2: "Reset password",
    reset_password_sent_email: "Reset password link has been sent to your email.",
    reset_password_sent_mobile: "Reset password link has been sent to your mobile.",
    next: "Next",
    back: "Back",
  },
  updateEmail: {
    title: "Update Email",
    member_profile: "Member Profile",
    updateEmail: "Update Email",
    new_email: "*New Email Address",
    sent: "Sent",
    send_token: "Send Token",
    email_errMsg1: "Please enter valid email",
    email_errMsg2: "Email already registered",
    token: "*Token",
    token_error: "Token error",
    update: "Update",
    update_success: "Update Success!",
    done: "Done",
    Msg1: "Both mobile and email already used",
    Msg2: "Email already used",
  },
  updateMobile: {
    title: "Update Mobile",
    member_profile: "Member Profile",
    updateMobile: "Update Mobile",
    new_mobile: "*New Mobile No.",
    sent: "Sent",
    send_token: "Send Token",
    mobile_errMsg1: "Please enter valid phone",
    mobile_errMsg2: "Phone already registered",
    token: "*Token",
    token_error: "Token error",
    update: "Update",
    update_success: "Update Success!",
    done: "Done",
    Msg1: "Both mobile and email already used",
    Msg2: "Email already used",
  },
  coupon: {
    title: "Vouchers",
    member_profile: "Member Profile",
    coupon: "Vouchers",
    active: "Active",
    redeemed: "Redeemed",
    expired: "Expired",
    type_of_vouchers_opt1: "Please select type of vouchers",
    type_of_vouchers_opt2: "Type A",
    type_of_vouchers_opt3: "Type B",
    type_of_vouchers_opt4: "Type C",
    type_of_vouchers_opt5: "Type D",
    expiry_date: "Expiry Date:",
    redeemed_store: "Redeemed Outlet:",
    vaild_date: "Valid Date",
    description: "Description",
    vouchers_code: "Voucher Code",
    done: "Done",
    no_coupon_msg: "No coupons available",
  },
  editProfile: {
    title: "Edit Profile",
    member_profile: "Member Profile",
    editProfile: "Edit Profile",
    general: "General Information",
    security: "Security",
    subscriptions: "Subscriptions",
    salutation: "*Salutation",
    salutation_opt1: "Please select your salutation",
    salutation_opt2: "Mr",
    salutation_opt3: "Mrs",
    salutation_opt4: "Mdm",
    salutation_opt5: "Miss",
    salutation_opt6: "Dr",
    salutation_errMsg: "Please select salutation",
    gender: "*Gender",
    gender_opt1: "Please select gender",
    gender_opt2: "Male",
    gender_opt3: "Female",
    gender_errMsg: "Please select gender",
    first_name: "*Given Name",
    first_name_errMsg: "Please enter given name",
    last_name: "*Family Name",
    last_name_errMsg: "Please enter family name",
    birth: "*Date of Birth",
    mobile: "*Mobile No.",
    mobile_change: "Change",
    email: "*Email",
    email_change: "Change",
    language: "*Language",
    language_opt1: "Please select your language preference",
    language_opt2: "English",
    language_opt3: "简体中文",
    language_opt4: "한국어",
    update: "Update",
    previous_password: "*Previous Password",
    previous_password_errMsg: "Previous password not correct",
    new_password: "*New Password",
    new_password_errMsg1: "Please enter password",
    new_password_errMsg2: "Password must contain at least 8 alphanumeric characters and with at least one uppercase and one special character (?!@$%&*)",
    confirm_password: "*Confirm Password",
    confirm_password_errMsg: "Please confirm password",
    subscriptions_txt1: "By checking the box(es) below, I give my consent to Imperial Treasure Restaurant Group Pte Ltd ('ITRG') to collect, use, store and/or disclose any personal data provided by me in connection with the Imperial Treasure Club Membership ('Data') for ITRG to share my data with any of its associated or related companies or other third parties so that I may enjoy the benefits arising from my membership of the Imperial Treasure Club, including benefits arising from ITRG’s relationship with service providers within and outside of Singapore.",
    subscriptions_txt2: "I agree to receive marketing messages from Imperial Treasure (Optional)",
    by_email: "By Email",
    by_mobile: "By Mobile",
    update_success: "Update Success!",
    done: "Done",
    password_update_success: "Password Update Success!",
  },
  profile: {
    title: "Member",
    logout: "Logout",
    click_to_renew: "CLICK TO RENEW​",
    activation_now: "ACTIVATE NOW",
    menber_id: "Member ID:",
    member: "member",
    active: "Active",
    expired: "Expired",
    salutation: "Salutation:",
    mobile: "Mobile No.:",
    birth: "Date of Birth:",
    password: "Password:",
    ellipsis: "········",
    change: "Change",
    gender: "Gender:",
    email: "Email:",
    cycle_start_date: "Tier Start Date:",
    first_joined_date: "First Joined Date:",
    optin_email: "Opt-in Email:",
    optin_sms: "Opt-in SMS:",
    cycle_expiry_date: "Cycle Expiry Date:",
    language_preference: "Language Preference:",
    spend: "KRW Spend",
    expires_on: "  Expires on",
    spend_required: "Nett Spend required",
    to_upgrade_to: "to upgrade to",
    nett_spend_required: "Nett Spend required to maintain",
    nett: "Nett",
    to_maintain_to: "to maintain",
    silver_member: "Silver",
    gold_member: " Gold",
    diamond_member: " Diamond",
    total_nett_spend: "Total Nett Spend",
    current_membership: "in current membership year*",
    i_dollar: "i-Dollar",
    last_update: "  Last Update : ",
    i_dollar_earned: "i-Dollar Earned",
    i_dollar_converted: "i-Dollar Converted",
    i_dollar_balance: "i-Dollar Balance",
    i_dollar_automatically: "$50 i-Dollars are automatically converted to KRW50k Cash e-voucher",
    vouchers: "Vouchers",
    currently_active: "Currently Active",
    cash_vouchers: "Cash Vouchers",
    food_vouchers: "Food/Promotion Vouchers",
    click_to_view: "CLICK TO VIEW",
    transaction: "Transactions",
    this_month: "This Month",
    last_3_months: "Last 3 Months",
    last_12_months: "Last 12 Months",
    transaction_details: "Transaction Details",
    transaction_date: "Transaction Date",
    transaction_time: "Transaction Time",
    outlet: "Outlet",
    receipt: "Receipt No.",
    payment_method: "Payment Method",
    receipt_details: "Receipt Details",
    zero: ".0000",
    i_dollars_eared: "i-DOLLARS EARNED:",
    total: "Total :",
    done: "Done",
    thankyou_msg: "Thank you for dining with us!",
    activate_membership: "Activate membership",
    activate_membership_1: "Activate your membership to enjoy the following privileges:",
    activate_membership_2: "Earn i-Dollar (i$1 with every KRW10,000 spent) on your spend at any participating restaurants. Accumulate i$50 to receive KRW50,000 cash e-voucher for use at any participating restaurants.",
    activate_membership_3: "",
    activate_membership_4: "",
    activate_membership_5: "",
    activate_membership_6: "",
    activate_membership_7: "",
    renew_membership: "",
    renew_membership_1: "",
    renew_membership_2: "",
    renew_membership_3: "",
    renew_membership_4: "",
    confirm_to_renew: "",
    confirm_to_activate: "Confirm To Activate",
    change_password: "Change Password",
    previous_password: "*Previous Password",
    previous_password_errMsg: "Previous password not correct",
    new_password: "*New Password",
    new_password_errMsg1: "Please enter & confirm password",
    new_password_errMsg2: "Password must contain at least 8 alphanumeric characters and with at least one uppercase and one special character (?!@$%&*)​",
    confirm_password: "*Confirm Password",
    close: "X CLOSE",
    member_activated: "Member activated",
    password_updated: "Password updated",
    view_more: "VIEW MORE",
    add_friend: "ADD",
    silver_tier: "Silver ",
    gold_tier: "Gold ",
    diamond_tier: "Diamond ",
    spend_date_format: "Expired year",
  },
  transactionDetails: {
    transaction_date: "Transaction Date",
    outlet: "Outlet",
    receipt_no: "Check/Receipt No",
    amount_spend: "Total Paid",
    amount_qualified_for_i_dollar: "Amount Qualified For i-Dollar",
    i_dollars: "i-Dollars",
    selected_start_date: "Selected start date",
    selected_end_date: "Selected end date",
    no_tranMsg: "No transaction records during the selected period.",
    action: "Action",
    apply: "Apply",
    view: "View",
  },
  contactUs: {
    title: "Contact Us",
    title_message: "Please complete and submit your feedback below and we will get back to you within 3-5 working days.",
    firstname: "Given Name",
    firstname_errMsg: "Please fill in your given name",
    lastname: "Family Name",
    lastname_errMsg: "Please fill in your family name",
    emailAddress: "Email Address",
    emailAddress_errMsg: "Please enter valid email",
    mobile: "Mobile No.",
    mobile_errMsg: "Please enter valid phone",
    type: "Type",
    type_opt1: "Please enter your feedback message type :",
    type_opt2: "Options",
    type_opt3: "Enquiry",
    type_opt4: "Feedback",
    type_opt5: "Newsletter Subscription",
    type_errMsg: "Please enter your feedback message type",
    message: "Message",
    message_errMsg: "Please enter your feedback message",
    captcha: "Captcha",
    captcha_errMsg: "Captcha incorrect",
    feedback_sucMsg: "Thank you for reaching out and providing us with valuable feedback. \nWe will respond to you very soon.",
    submit: "Submit",
  },
  languageSetting: {
    title: "Language Setting",
    setting: "Setting",
    language_preference: "Language Preference",
    language_preference_opt1: "Please select your language preference",
    language_preference_opt2: "English",
    language_preference_opt3: "简体中文",
    language_preference_opt4: "한국인",
    language_preference_errMsg: "Please select language preference",
    submit: "Submit",
  },
};